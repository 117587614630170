import { defineStore } from 'pinia';
import * as idb from '@/utils/indexdb.js';
import avatarurl from '@/assets/avatar.jpg';
import Worker from '@/workers.js?worker'
import MD5 from 'md5-es';
const sysinfo = localStorage.getItem("sysinfo");
const vars = localStorage.getItem("vars");
let delwatch,timerid;
export const useStore = defineStore('main', {
  state: () => {
    return {
      sid: '',//登录后的API令牌
      isonline:true,//是否在线模式
      sidebarActive: '工作台',
      collapsed: false,
      userInfo: null,//用户信息
      menu:null,//用户菜单
      vars:vars ? JSON.parse(vars) : {},//系统变量设置
      sysinfo:sysinfo ? JSON.parse(sysinfo) : {},//系统信息
      dbupdating:null,//正在更新的本地数据库表
      cfg:null,
    }
  },
  actions: {
    gethash(w1, w2) {
      return MD5.hash(MD5.hash(w1 + w2) + w1);
    },
    async login(username,pwd,usephone) {
      let data,r;
      const hash = this.gethash(username,pwd);
      const nowstamp = Math.floor((new Date()).getTime() / 1000);
      try {
        const { data:result } = await this.axios.post("/Index/login", { username, pwd ,usephone});
        if (result.success) {
          data = result.data;
          this.isonline = true;
          localStorage.setItem("lastlogin", nowstamp);
          if (!usephone) {
            idb.updateDb({ loginedadmin: [{ hash, data }] }).then(async () => {
              const allrow = await idb.getAll("loginedadmin");
              for (const login of allrow) {
                if (login.data.user.admin_name == username && login.hash != hash) idb.del("loginedadmin", login.hash);
              }
            });
          }
          console.log(data);
        }
        r = result;
      } catch (ex) {//网络无法连接时做本地登录处理
        const lastlogin = parseInt(localStorage.getItem("lastlogin"));
        if (!usephone && nowstamp - lastlogin <= 24 * 3600) {//24小时以内允许离线登录
          const row = await idb.find("loginedadmin", hash);
          if (row) {
            data = row.data;
            data.sid = "";
            this.isonline = false;
          }
        }
        r = { success: data ? true : false, msg: data ? "本地登录成功" : "本地登录失败" };
      }
      finally {
        if (data) {
          if (!data.user.desc) data.user.desc = {};
          this.userInfo = {
            name: data.user.admin_name,
            phone: data.user.mobile?data.user.mobile:"",
            avatar: data.user.desc.avatar?data.user.desc.avatar:avatarurl,
            data:data.user,
          };
          this.sid = data.sid;
          this.init();
          this.menu = data.menu;
        }
        return r;
      }
    },
    init() {
      delwatch = this.$subscribe((mutation, state) => sessionStorage.setItem("state", JSON.stringify(state)));//会话状态持久化
      const worker = new Worker();
      const state = this;
      worker.onerror = (e) => console.log(e);
      worker.onmessage = async (e) => {
        const { type, data } = e.data;
        const sid = state.sid;
        switch (type) {
          case "updatetime":
            localStorage.setItem("lastcheck", data);
            timerid=setTimeout(() => {
              worker.postMessage([localStorage.getItem("lastcheck"), sid]);
            }, 5000);
            break;
          case "beginupdate":
            state.dbupdating = data;
            break;
          case "updatedone":
            state.dbupdating = null;
            let obj = {};
            for (const prop of data) {
              if (this[prop]) {
              obj[prop] = await idb.getAll(prop);
              }
            }
            this.$patch(obj);
            break;
          default:
            break;
        }
      }
      worker.postMessage([localStorage.getItem("lastcheck"), this.sid]);
    },
    delSession() {
      this.router.replace("/");
      if (delwatch) delwatch();
      if (timerid) clearInterval(timerid);
      this.$reset();
      sessionStorage.clear();
    },
    logout() {
      this.axios.get("/Index/logout").finally(() => {
        this.delSession();
      })
    },
    saveRole(roleid,data) {
      this.axios.post("/SystemSet/editRole", data, { roleid: roleid ? ("$." + roleid) : "" }).then(({ data }) => {
        if (data.success) this.router.push("/roles")
      });
    },
    /**
     * 根据代码返回角色名
     * @param {array} roles 
     * @returns {string}
     */
    getRoleNames(roles) {
      const rolescfg = this.cfg.roles || [];
      return roles.map((v) => rolescfg[v].rolename).join(",");
    },
    /**
     * 根据部门fullkey返回部门名称
     * @param {string} dptid 
     * @returns string
     */
    getDptName(dptidpath) {
      if (!dptidpath) return "未填";
      const keypath = dptidpath.split(".").filter((v) => v != "nodes" && v != "$");
      const dptscfg = this.cfg.dpts || {};
      let str = "";
      keypath.reduce((pv, cv,ci) => {
        let node;
        if (ci==0) {
          node = pv[cv];
          str =node.dptname;
        } 
        else {
          node = pv.nodes[cv];
          str += "/"+node.dptname;
        }
        return node;
      },dptscfg);
      return str || "未填";
    }


  }
})

export default useStore
