<template>
  <Page title="客户信息">

    <t-space direction="vertical" class="w-full">

      <t-tabs class="sticky-tab" :value="tabActive" placement="left" theme="normal" @change="handlerChange">

        <!-- 客户资料 -->
        <t-tab-panel value="客户资料">
          <template #label> <t-icon name="user" class="tabs-icon-margin" /> 客户资料 </template>
          <h2 class="text-xl pl-6 pt-2 flex items-center gap-1">基本信息 
            <t-button shape="circle" variant="text"><template #icon><t-icon name="edit" /></template></t-button>
          </h2>
          <div class="grid grid-cols-2 p-6">
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">客户姓名</h3>
              <p class="pl-6 text-gray-700">陈陈飞飞</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">性别</h3>
              <p class="pl-6 text-gray-700">男</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">生日</h3>
              <p class="pl-6 text-gray-700">2000年1月1日</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">身份证号</h3>
              <p class="pl-6 text-gray-700">123456789012345678</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">籍贯</h3>
              <p class="pl-6 text-gray-700">籍贯</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">居住地址</h3>
              <p class="pl-6 text-gray-700">居住地址</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">联系电话</h3>
              <p class="pl-6 text-gray-700 flex items-center gap-4">13012345678 <t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="add" /></t-button></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">微信号</h3>
              <p class="pl-6 text-gray-700 flex items-center gap-1"></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">邮箱</h3>
              <p class="pl-6 text-gray-700 flex items-center gap-1"></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">职业</h3>
              <p class="pl-6 text-gray-700 flex items-center gap-1"></p>
            </div>
            <div class="flex pt-6 items-center col-span-2">
              <h3 class="w-40 font-normal text-left text-gray-500">资料</h3>
              <p class="pl-6 text-gray-700 flex items-center gap-4"> <a href="#" target="_blank" class="text-primary">身份证复印件.jpg</a> <t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="upload" /></t-button></p>
            </div>
          </div>

          <h2 class="text-xl pt-6 pl-6">商务信息</h2>
          <div class="grid grid-cols-2 p-6">
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">来源</h3>
              <p class="pl-6 text-gray-700"></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">首次接触时间</h3>
              <p class="pl-6 text-gray-700"></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">推荐人</h3>
              <p class="pl-6 text-gray-700"></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">首次接触的工作人员</h3>
              <p class="pl-6 text-gray-700"></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">所属地区</h3>
              <p class="pl-6 text-gray-700"></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">客户等级</h3>
              <p class="pl-6 text-gray-700"></p>
            </div>
            <div class="flex pt-6 items-center col-span-2">
              <h3 class="w-40 font-normal text-left text-gray-500">人脉关联</h3>
              <p class="pl-6 text-gray-700 flex items-center gap-4"> <a href="#" target="_blank" class="text-primary">查看</a> <t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="usergroup-add" /></t-button></p>
            </div>
          </div>
        </t-tab-panel>

        <t-tab-panel value="报备信息">
          <template #label> <t-icon name="bulletpoint" class="tabs-icon-margin" /> 报备信息 </template>
          <h2 class="text-xl pl-6 pt-2 flex items-center gap-1">报备信息 
            <t-button shape="circle" variant="text"><template #icon><t-icon name="add" /></template></t-button>
          </h2>

          <t-timeline mode="alternate" class="p-6">
            <t-timeline-item dot-color="primary" :label="item.date" v-for="item in reportTimeline" :key="item.date">
              <div>销售员：{{ item.user }}，报备客户 {{ item.reportTime }} 入园参观，人数：{{ item.number }}。<span v-if="item.cancel">{{ item.user }}已撤销报备。</span></div>
              <div class="mt-2 flex gap-2" v-if="!item.cancel"><t-button theme="default" size="small" variant="outline">报备取消</t-button><t-button theme="default" size="small" variant="outline">修改报备</t-button></div>
            </t-timeline-item>
          </t-timeline>
        </t-tab-panel>

        <!-- 会话记录 -->
        <t-tab-panel value="会话记录">
          <template #label> <t-icon name="chat" class="tabs-icon-margin" /> 会话记录 </template>
          <h2 class="text-xl pl-6 pt-2 flex items-center gap-1">会话记录 
            <t-button shape="circle" variant="text" @click="addConversation">
              <template #icon><t-icon name="add" /></template></t-button>
          </h2>
          
          <t-timeline mode="alternate" class="p-6">
            <t-timeline-item dot-color="primary" :label="item.date" v-for="item in conversationTimeline" :key="item.date">
              <div>{{ item.user }}与客户会话：{{ item.content }}。</div>
              <div class="text-sm mt-2 text-gray-500">回访预约：{{ item.revisit }}</div>
              <div class="mt-2 flex gap-2"><t-button theme="default" size="small" variant="outline">会话修改</t-button><t-button theme="default" size="small" variant="outline">取消预约</t-button></div>
            </t-timeline-item>
          </t-timeline>
        </t-tab-panel>

        <!-- 合同 -->
        <t-tab-panel value="合同">
          <template #label> <t-icon name="wallet" class="tabs-icon-margin" /> 合同 </template>
          <h2 class="text-xl pl-6 pt-2 flex items-center gap-1">合同
            <t-button shape="circle" variant="text" @click="addContract"><template #icon><t-icon name="add" /></template></t-button>
          </h2>

          <div class="grid grid-cols-2 p-6 mb-12" v-for="item in contractData">
            <div class="flex items-center col-span-2">
              <h3 class="w-40 font-normal text-left text-gray-500">合同编号</h3>
              <p class="pl-6 text-gray-700">{{ item.no }} <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span></p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">墓位</h3>
              <p class="pl-6 text-gray-700">{{ item.location }}</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">穴位数</h3>
              <p class="pl-6 text-gray-700">{{ item.tombNum }}</p>
            </div>
            <div class="flex pt-6 items-center col-span-2">
              <h3 class="w-40 font-normal text-left text-gray-500">使用人</h3>
              <p class="pl-6 text-gray-700">
                {{ item.dperson1 }}/{{ item.dperson1Sex }}
                <template v-if="item.dperson2">
                、{{ item.dperson2 }}/{{ item.dperson2Sex }}
                </template>
              </p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">签约人</h3>
              <p class="pl-6 text-gray-700">{{ item.signatory }}</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">电话</h3>
              <p class="pl-6 text-gray-700">{{ item.phone }}</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">公示价格</h3>
              <p class="pl-6 text-gray-700">¥{{ item.price }}</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">合约价格</h3>
              <p class="pl-6 text-gray-700">¥{{ item.hyPrice }}<span v-if="item.onSales">（优惠：{{ item.onSales }}元）</span></p>
            </div>
            <div class="flex pt-6 flex-col col-span-2">
              <h3 class="font-normal text-left text-gray-500">明细</h3>
              <t-table bordered class="pt-6" :columns="contractPriceColumns"></t-table>
              <div class="flex pt-6 items-center">
                <h3 class="w-40 font-normal text-left text-gray-500">附件</h3>
                <p class="pl-6 text-gray-700 flex gap-6">
                  <a :href="file.url" class="text-primary" v-for="file in item.attachment">{{ file.filename }}</a>
                </p>
              </div>
            </div>
            <div class="col-span-2">
              <t-divider />
            </div>
            <div class="flex items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">销售员</h3>
              <p class="pl-6 text-gray-700">{{ item.salesperson }}</p>
            </div>
            <div class="flex items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">签约时间</h3>
              <p class="pl-6 text-gray-700">{{ item.date }}</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">录入人</h3>
              <p class="pl-6 text-gray-700">{{ item.createUser }}</p>
            </div>
            <div class="flex pt-6 items-center">
              <h3 class="w-40 font-normal text-left text-gray-500">录入时间</h3>
              <p class="pl-6 text-gray-700">{{ item.createTime }}</p>
            </div>
            <div class="col-span-2">
              <t-divider />
            </div>
            <div class="flex flex-col col-span-2">
              <h3 class="font-normal text-left text-gray-500">收费情况</h3>
              <div class="grid grid-cols-3 border w-full mt-6 text-sm text-gray-700 rounded-sm">
                <div class="py-3 px-4 border-r border-b">应收：</div>
                <div class="py-3 px-4 border-r border-b">实收：</div>
                <div class="py-3 px-4 border-b">待收：</div>
                <div class="py-3 px-4 border-r">抵扣：</div>
                <div class="py-3 px-4 border-r">未收：</div>
                <div class="py-3 px-4">优惠金额：</div>
              </div>
            </div>
            <div class="flex pt-6 items-center col-span-2">
              <h3 class="w-40 font-normal text-left text-gray-500">状态</h3>
              <p class="pl-6 text-gray-700">{{ item.status }}</p>
            </div>
            <div class="col-span-2">
              <t-divider />
            </div>
            <div class="flex items-center col-span-2">
              <h3 class="w-40 font-normal text-left text-gray-500">其他操作</h3>
              <p class="pl-6">
                <t-button theme="default" variant="text">合同打印/套打</t-button>
                <t-button theme="default" variant="text">标准墓</t-button>
                <t-button theme="default" variant="text">艺术墓</t-button>
                <t-button theme="default" variant="text">转单</t-button>
                <t-button theme="default" variant="text">上传附件</t-button>
              </p>
            </div>
          </div>

        </t-tab-panel>

        <t-tab-panel value="使用人">
          <template #label> <t-icon name="user-avatar" class="tabs-icon-margin" /> 使用人 </template>
          <h2 class="text-xl pl-6 pt-2 flex items-center gap-1">使用人
            <t-button shape="circle" variant="text" @click="addDperson"><template #icon><t-icon name="add" /></template></t-button>
          </h2>

          <t-collapse :default-value="0" expand-icon-placement="right" :borderless="true" @change="dpersonPanelChange">
            <t-collapse-panel :value="index" :header="'墓位：' + item.location" v-for="(item, index) in dpersonData">

              <div class="grid grid-cols-2">
                <div class="flex items-center">
                  <h3 class="w-40 font-normal text-left text-gray-500">使用人</h3>
                  <p class="pl-6 text-gray-700"> {{ item.dperson1 }}
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span>
                  </p>
                  <p class="pl-6 text-gray-700" v-if="item.dperson2"> {{ item.dperson2 }}
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span>
                  </p>
                </div>

                <div class="flex items-center">
                  <h3 class="w-40 font-normal text-left text-gray-500">墓权证</h3>
                  <p class="pl-6 text-gray-700"> {{ item.certificate }}
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="add" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="print" /></t-button></span>
                  </p>
                </div>

                <div class="flex pt-6 flex-col col-span-2">
                  <h3 class="font-normal text-left text-gray-500">基本信息</h3>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">姓名</h3>
                  <p class="pl-6 text-gray-700">{{ item.dperson1 }}</p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40 font-normal text-left text-gray-500">性别</h3>
                  <p class="pl-6 text-gray-700">{{ item.dperson1Sex }}</p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">身份证号</h3>
                  <p class="pl-6 text-gray-700">123456789012345678</p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40 font-normal text-left text-gray-500">身份证图片</h3>
                  <p class="pl-6 text-gray-700"><a href="#" class="text-primary mr-6">正面</a> <a href="#" class="text-primary">背面</a></p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">生辰</h3>
                  <p class="pl-6 text-gray-700 flex gap-6"><span>（新）</span><span>（旧）</span></p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">忌日</h3>
                  <p class="pl-6 text-gray-700 flex gap-6"><span>（新）</span><span>（旧）</span></p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">享年</h3>
                  <p class="pl-6 text-gray-700 flex gap-6">XX岁</p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">照片</h3>
                  <p class="pl-6 text-gray-700 flex gap-6"><a href="#" class="text-primary">xxxxxx.jpg</a></p>
                </div>

                <div class="flex pt-6 flex-col col-span-2">
                  <h3 class="font-normal text-left text-gray-500">安葬信息 <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="add" /></t-button></span></h3>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">安葬时间</h3>
                  <p class="pl-6 text-gray-700 flex gap-6">2000年01月01日 00:00:00</p>
                </div>

                <div class="flex items-center pt-6 col-span-2">
                  <h3 class="font-normal text-left text-gray-500">碑文</h3>
                  <p class=" text-gray-700">
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="add" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="print" /></t-button></span>
                  </p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40 font-normal text-left text-gray-500">碑文正面稿</h3>
                  <p class="pl-6 text-gray-700">
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="print" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span>
                  </p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40 font-normal text-left text-gray-500">碑文背面稿</h3>
                  <p class="pl-6 text-gray-700">
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="print" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span>
                  </p>
                </div>

                <div class="flex items-center pt-6 col-span-2">
                  <h3 class="w-40 font-normal text-left text-gray-500">碑文扫描件</h3>
                  <p class="pl-6 text-gray-700 flex gap-6">
                    <span class="mr-6 text-primary">碑文扫描件1 <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span></span>
                    <span class="mr-6 text-primary">碑文扫描件2 <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span></span>
                  </p>
                </div>

                <div class="flex pt-6 flex-col col-span-2">
                  <h3 class="font-normal text-left text-gray-500">施工单 <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="add" /></t-button></span></h3>
                </div>

                <div class="flex items-center pt-6 col-span-2">
                  <h3 class="w-40  font-normal text-left text-gray-500">施工单号</h3>
                  <p class="pl-6 text-gray-700 flex gap-6">SI123123123123</p>
                  <p class="pl-6 text-gray-700">
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="browse" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="print" /></t-button></span>
                  </p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">施工时间</h3>
                  <p class="pl-6 text-gray-700 flex gap-6">2000年01月01日 00:00:00</p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">施工人</h3>
                  <p class="pl-6 text-gray-700 flex gap-6">admin</p>
                </div>

                <div class="flex pt-6 flex-col col-span-2">
                  <h3 class="font-normal text-left text-gray-500">墓位维护 <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="add" /></t-button></span></h3>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">墓位维护开始时间</h3>
                  <p class="pl-6 text-gray-700 flex items-center">
                    <span>2000年01月01日</span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span>
                  </p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">墓位维护结束时间</h3>
                  <p class="pl-6 text-gray-700 flex items-center">
                    <span>2020年01月01日</span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="edit" /></t-button></span>
                    <span class="pl-2"><t-button theme="primary" size="small" shape="circle" variant="base"><t-icon name="delete" /></t-button></span>
                  </p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">维护费单价</h3>
                  <p class="pl-6 text-gray-700 flex gap-6">¥50元/年</p>
                </div>

                <div class="flex items-center pt-6">
                  <h3 class="w-40  font-normal text-left text-gray-500">离到期时间</h3>
                  <p class="pl-6 text-gray-700 flex gap-6">XXXX天</p>
                </div>

              </div>

            </t-collapse-panel>
          </t-collapse>

        </t-tab-panel>

        <t-tab-panel value="管理费">
          <template #label> <t-icon name="view-module" class="tabs-icon-margin" /> 管理费 </template>
        </t-tab-panel>

        <t-tab-panel value="安葬单">
          <template #label> <t-icon name="tools" class="tabs-icon-margin" /> 安葬单 </template>
          <p style="padding: 25px">
            {{ `安葬单` }}
          </p>
        </t-tab-panel>
        
        <t-tab-panel value="订单">
          <template #label> <t-icon name="shop" class="tabs-icon-margin" /> 订单 </template>
          <p style="padding: 25px">
            {{ `订单` }}
          </p>
        </t-tab-panel>

        <t-tab-panel value="交易流水">
          <template #label> <t-icon name="money-circle" class="tabs-icon-margin" /> 交易流水 </template>
          <p style="padding: 25px">
            {{ `交易流水` }}
          </p>
        </t-tab-panel>

        <t-tab-panel value="联系人">
          <template #label> <t-icon name="user-talk" class="tabs-icon-margin" /> 联系人 </template>
          <p style="padding: 25px">
            {{ `联系人` }}
          </p>
        </t-tab-panel>

        <t-tab-panel value="人脉网络">
          <template #label> <t-icon name="fork" class="tabs-icon-margin" /> 人脉网络 </template>
          <p style="padding: 25px">
            {{ `人脉网络` }}
          </p>
        </t-tab-panel>
      </t-tabs>
    </t-space>

    <!-- 添加会话信息弹窗 -->
    <t-dialog v-model:visible="conversationDialogShow" :closeBtn="false" header="新增会话" :on-confirm="conversationConfirm" width="660px">
      <t-form ref="nodeform" :data="conversationFormData" :rules="conversationFormRules">
        <t-form-item label="会话内容" labelWidth="130px" name="conversationDesc">
          <t-textarea :autosize="{ minRows: 3, maxRows: 5 }" v-model="conversationFormData.desc"/>
        </t-form-item>
        <t-form-item label="预约下次会话时间" labelWidth="130px" name="conversationTime">
          <t-date-picker enable-time-picker allow-input v-model="conversationFormData.time" clearable />
        </t-form-item>
      </t-form>
    </t-dialog>

  </Page>
</template>

<script setup>
import { ref } from 'vue'

const tabActive = ref('客户资料')
const conversationDialogShow = ref(false)

const handlerChange = (newValue) => {
  tabActive.value = newValue;
}

const reportTimeline = [
  {
    user: '张三',
    reportTime: '2022-05-03',
    number: '1',
    cancel: false,
    date: '2022-03-01 10:00'
  },
  {
    user: '李四',
    reportTime: '2022-04-01',
    number: '2',
    cancel: false,
    date: '2022-02-01 17:20'
  },
  {
    user: '张三',
    reportTime: '2022-05-03',
    number: '1',
    cancel: true,
    date: '2022-01-01 13:12'
  }
]

const conversationTimeline = [
  {
    user: '张三',
    content: '明天上门介绍',
    revisit: '2022-01-02 09:30',
    date: '2022-01-01 09:15'
  },
  {
    user: '李四',
    content: '下午来园区参观',
    revisit: '2000-01-01 09:30',
    date: '2022-01-01 10:00'
  }
]

const conversationFormData = reactive({
  desc: '',
  time: ''
})

const conversationFormRules = {
  "desc": [{ required: true }],
  "time": [],
}

const addConversation = () => {
  conversationDialogShow.value = true
}

const contractData = [
  {
    id: 1,
    no: '2022-0005133',
    client: '张忠',
    signatory: '张忠',
    phone: '12345678901',
    dperson1: '张三',
    dperson1Sex: '男',
    dperson2: '李四',
    dperson2Sex: '女',
    location: '福孝园D区-02-06号',
    tombNum: '1',
    date: '2023-11-10 00:00:00',
    salesperson: 'Admin',
    createUser: 'Admin',
    createTime: '2023-11-01 00:00:00',
    price: '33800.00',
    onSales: '1000.00',
    hyPrice: '32800.00',
    attachment: [
      { filename: 'xxx.jpg', url: '#' },
      { filename: 'xxx.png', url: '#' }
    ],
    status: '已完结'
  }
]

const addContract = () => {

}

const conversationConfirm = () => {

}

const contractPriceColumns = [
  { colKey: '1', title: ' '},
  { colKey: '2', title: ' '},
  { colKey: '3', title: ' '},
  { colKey: '4', title: ' '},
]

const dpersonData = [
  {
    id: 1,
    location: '福孝园D区-02-06号',
    dperson1: '张三',
    dperson1Sex: '男',
    dperson2: '李四',
    dperson2Sex: '女',
    certificate: '21321312321'
  }
]

</script>

<style>
.tabs-icon-margin {
  margin-right: 4px;
}

.t-timeline-label.t-timeline-label--alternate.t-timeline-left .t-timeline-item__wrapper {
  margin-left: 140px;
}

.t-timeline-item__dot {
  left: 2px;
}

.t-collapse-panel__wrapper .t-collapse-panel__header {
  padding: 24px;
}

.t-collapse-panel__wrapper .t-collapse-panel__content {
  padding: 0 24px 24px 24px;
}
</style>