<template>
    <t-upload :action="baseURL+'/Index/uploadfile?sid='+store.sid" :request-method="uploadfile" ref="upref" v-model="files" auto-upload :before-upload="confirmupdate" :theme="theme" :accept="(theme=='image'||theme=='image-flow')?'image/*':'*'" :abridgeName="[4,4]"  v-bind="$attrs"></t-upload>
     <t-dialog ref="dialogref" :visible="showconfirm" header="图片预览" :close-btn="true" confirm-btn="确认上传" cancel-btn="取消" :on-confirm="()=>{confirmup=true;showconfirm=false;}" :on-close="()=>showconfirm=false" mode="modal" width="80%">
        <template #body>
            <canvas style="max-width:100%;height: auto;" ref="cvsref"></canvas>
        </template>
      </t-dialog>
</template>
<script lang="jsx" setup>
import { computed, ref, watch } from 'vue';
import * as request from '@/utils/request.js';
const baseURL = APIBASE;
const store = useStore();
const props = defineProps({
    theme: {
        type: String,
        default: "image",
        validator(value) {
            return ['file', 'image','single-input','file-flow','image-flow'].includes(value);
        } 
    },
    modelValue: {
        type: Array,
        default() {
            return [];
        },
    },
    maxW: {
        type: Number,
        default:2000,
    },
});
const emit = defineEmits(['update:modelValue']);
const files = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
})
const upref = ref();
const nozip = ref(false);
async function uploadfile(file) {
    if (!nozip.value) {
        const dataUrl = cvsref.value.toDataURL('image/jpeg', 0.6);
        const pre = APIBASE.replace(/api.php/,"");
        const {data:ret}=await request.post('/Index/uppic', { data: dataUrl.substr(23) });
        return ret.success ? { status: 'success', response: { url: `${pre}${ret.data.path}` } } : { status: 'fail', error: ret.msg };
    }
    else {
        const pre = APIBASE.replace(/api.php/,"");
        const form = new FormData();
        form.append('file', file[0].raw);
        const {data:ret}=await request.post('/Index/uploadfile', form);
        return ret.success ? { status: 'success', response: { url: `${pre}${ret.data.path}` } } : { status: 'fail', error: ret.msg };
    }
}
async function confirmupdate(file) {
    const result = await new Promise((resolve) => {
        if (!file.type.match(/image/i) || (file.name.split(".").pop().toLowerCase())=="svg") {
            nozip.value = true;
            resolve(true);
        }
        else {
            nozip.value = false;
            const reader = new FileReader();
            reader.onload = function (e) {
                compress(reader.result);
                showconfirm.value = true;
            };
            reader.readAsDataURL(file.raw);
            const unwatch = watch(showconfirm, (newval) => {
                if (!newval) {
                    resolve(confirmup.value);
                    unwatch();
                }
            });
        }
    });
    return result;
}
const cvsref = ref();
function compress(res) {
    const img = new Image();
    const cvs = cvsref.value;
    const maxW = props.maxW;
    img.onload = function () {
        const ctx = cvs.getContext('2d');
        if (img.width > maxW) {
            img.height *= maxW / img.width;
            img.width = maxW;
        }
        cvs.width = img.width;
        cvs.height = img.height;
        ctx.clearRect(0, 0, cvs.width, cvs.height);
        ctx.drawImage(img, 0, 0, img.width, img.height);
    }
    img.src = res;
}
const dialogref = ref();
const showconfirm = ref(false);
const confirmup = ref(false);
</script>