<template>
    <Page :title="title">
    <t-form :data="store.feetype" :rules="rules" :colon="true" @submit="store.saveFeeType(fullkey)" ref="form" >
      <t-form-item label="上级类别">
        <h5>{{ store.getTypeName(action=="edit"?store.feetype.pkey:fullkey) }}</h5>
      </t-form-item>
      <t-form-item label="类别编码" name="feetypecode" help="仅包含英文字母与数字，可以有连字符，长度小于20">
        <t-input v-model="store.feetype.feetypecode" :style="{ width: '256px' }" :maxlength="20" show-limit-number></t-input>
      </t-form-item>

      <t-form-item label="类别名称" name="text">
        <t-input v-model="store.feetype.text" :style="{ width: '256px' }" :maxlength="10" show-limit-number></t-input>
      </t-form-item>

      <t-form-item label="系统类别" name="issys">
        <t-switch v-model="store.feetype.issys"/>
      </t-form-item>
      <t-form-item>
        <t-space size="small">
          <t-button theme="primary" type="submit">保存</t-button>
          <t-button theme="default" variant="base" @click="router.back()">返回</t-button>
        </t-space>
      </t-form-item>
    </t-form>
    </Page>
</template>
<script setup>
import { useFeeTypeStore } from '@/store/feetypes';
const title = props.action=="add"?"添加费用类别":"修改费用类别";
const props = defineProps(["action", "feetypecode"]);
const store = useFeeTypeStore();
const router = useRouter();
watchEffect(() => {
  if (props.action=="edit" && props.feetypecode && store.feetypes) store.feetype = store.getFeetype(props.feetypecode);
});
onBeforeUnmount(() => {
  store.feetype = {};
} );
const fullkey = computed(() => {
  const f=store.feetypearr?.filter(r => r.key == props.feetypecode);
  return f && f.length>0?f.pop().fullkey:"";
});
const rules = {
  feetypecode: [
    { required: true, type: 'error' },
    { pattern: /^[\w\-_]{1,20}$/, type: 'warning' },
    {
      validator: (val) => {
        for (const row of store.feetypearr||[]) {
          if (row.key == val && row.key != props.feetypecode) return { result: false, message: `编码与类别${row.obj.text}相同` }
        }
        return { result: true }
      }
    }
  ],
  text: [{ required: true, type: 'error' }],
}
</script>