<template>
  <Page>
    <div v-if="loading">数据加载中......</div>
    <t-tree v-else ref="tree" :data="treeData" :label="getLabel" hover expand-all :expand-on-click-node="false" :expand-parent="true" line>
      <template #operations="{ node }">
        <div class="space-x-2">
          <t-button size="small" variant="outline" @click="append(node)"> 添加下属部门 </t-button>
          <t-button size="small" variant="outline" @click="insertAfter(node)"> 添加同级部门 </t-button>
          <t-button size="small" variant="outline" @click="setData(node)"> 修改部门信息 </t-button>
          <t-button size="small" variant="base" theme="danger" @click="remove(node)"> 删除部门 </t-button>
        </div>
      </template>
    </t-tree>

    <t-dialog v-model:visible="dialogShow" header="请填入部门信息" :on-confirm="onConfirm">
      <h5 v-if="nodeTemp?.status=='append'">上级部门：{{ getpath(nodeTemp) }}</h5>
      <t-form ref="nodeform" :data="depFormData" :rules="formrules">
        <t-form-item label="部门编码" name="dptcode" help="仅包含英文字母与数字，可以有连字符，长度小于20">
          <t-input v-model="depFormData.dptcode" :maxlength="20" show-limit-number />
        </t-form-item>
        <t-form-item label="部门名称" name="dptname">
          <t-input v-model="depFormData.dptname" :maxlength="10" show-limit-number></t-input>
        </t-form-item>
        <t-form-item label="部门主管" name="dptheader">
          <t-input v-model="depFormData.dptheader"></t-input>
        </t-form-item>
      </t-form>
    </t-dialog>
  </Page>
</template>

<script setup>
import { ref } from 'vue'
import { DialogPlugin, MessagePlugin } from 'tdesign-vue-next'
import {getTreeData,getFullPath} from '@/utils/funcs.js'
let loading = ref(true);
const request = inject("axios");
const nodeform = ref(null);
let dpts=ref({});

// 初始值
const treeData = computed(()=>getTreeData(dpts.value));
function getpath(node) {
  if (!node) return "";
  return node.getPath().map((itemnode) => itemnode.data.dptname).join("/");
}

request.get("/SystemSet/dptList").then(({ data })=> {
  if (data) {
    dpts.value = data.data.rows;
    loading.value = false;
  }
});
const getLabel = (h, node) => {
  const header=node.data.dptheader||"未填"
  return node.data.dptname + `(代码：${node.value}，主管：${header})`;
}

// 部门 FORM 数据初始化
const depFormData = reactive({
  dptname: '',
  dptcode: '',
  dptheader: ''
})

const tree = ref(null)
const dialogShow = ref(false)
let nodeTemp

const formrules = {
  "dptcode": [{ required: true }, { pattern: /^[\w\-]{1,20}$/ }, {
    validator: (val) => {
      const codecnt = tree.value.getItems().filter((node) => node.value == val).length;
      return { result:  codecnt===0 || (nodeTemp.status=="edit" && nodeTemp.value==val && codecnt== 1) , message:"部门代码不允许重复"}
  }}],
  "dptname":[{required:true},{max:20}],
}
// 添加部门
const insertAfter = (node) => {
  clearTemp()
  nodeTemp = node
  nodeTemp.status = 'insert'
  dialogShow.value = true
}

// 添加子部门
const append = (node) => {
  clearTemp()
  nodeTemp = node
  nodeTemp.status = 'append'
  dialogShow.value = true
}

// 修改部门名字
const setData = (node) => {
  nodeTemp = node
  nodeTemp.status = 'edit'
  dialogShow.value = true
  depFormData.dptcode = node.value;
  depFormData.dptname = node.data.dptname;
  depFormData.dptheader = node.data.dptheader;
}

// 删除部门
const remove = async(node) => {
    const confirmDia = DialogPlugin({
      header: '确认删除当前所选部门？',
      body: '删除后，部门及下属部门将被清空，且无法恢复。',
      confirmBtn: {
        content: '确定',
        variant: 'base',
        theme: 'danger'
      },
      cancelBtn: '取消',
      onConfirm: async () => {
        const { data: result } = await request.get("/SystemSet/delDpt", { dptid: node.value });
        //tree.value.remove(node.value)
        if (result.success) {
          confirmDia.hide();
          dpts.value = result.data;
        }
      },
      onClose: () => {
        confirmDia.hide()
      }
    })
}
function renewdata( data ) {
  clearTemp()
  dialogShow.value = false
  dpts.value = data;
  nodeform.value.clearValidate();
}
const onConfirm = async (context) => {
  const valiresult = await nodeform.value.validate();
  // 进行表单非空验证
  if (valiresult===true) {
    let nodepath = getFullPath(nodeTemp);
    // 根据 Status 状态进行不同点击事件的操作
    if (nodeTemp.status == 'edit') {
      const pnode = tree.value.getParent(nodeTemp.value);
      request.post("/SystemSet/editDpt", depFormData, { dptid: nodepath,pkey:pnode?getFullPath(pnode):"" }).then(({ data})=> {
        if(data.success)renewdata(data.data);
      })
    } else if (nodeTemp.status == 'append') {
      request.post("/SystemSet/editDpt", depFormData, { pkey: nodepath }).then(({ data})=> {
        if(data.success)renewdata(data.data);
      })

    } else if (nodeTemp.status == 'insert') {
      const pnode = tree.value.getParent(nodeTemp.value);
      nodepath = pnode ? getFullPath(pnode) : "";
      request.post("/SystemSet/editDpt", depFormData, { pkey: nodepath }).then(({ data})=> {
        if(data.success)renewdata(data.data);
      })
    }
  } else {
    MessagePlugin.error({ content: '请补充部门信息' })
  }
}

const clearTemp = async () => {
  nodeform.value.clearValidate();
  depFormData.dptname = ''
  depFormData.dptcode = ''
  depFormData.dptheader = ''
  nodeTemp = ''
}
</script>
