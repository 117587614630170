const dbapi = globalThis.indexedDB;
const dbname = "mu6";
let db,dbversion;
 /**
  * getdb
  *
  * @param {boolean} rebuild
  * 
  * @return {IDBDatabase}
  */
async function getdb (rebuild){
    if (db) {
        dbversion = parseInt(db.version);
        return db;
    }
    if (!dbversion) dbversion = 1;
    const e=await new Promise((resovle, reject) => {
        if (rebuild) {
            dbversion++;
        }
        /** @type {IDBOpenDBRequest}*/
        let req =dbapi.open(dbname,dbversion);
        req.onerror = reject;
        req.onupgradeneeded = init;
        req.onsuccess = resovle;
    });
    db = e.target.result;
    db.onerror = (e) => console.error(e);
    return db;
} 
/**
 * init
 * 初始化数据库
 * @param {IDBVersionChangeEvent} event
 * 
 */
function init(event) {
    /** @type {IDBDatabase} */
    const db = event.target.result;
    for (let oldstore of db.objectStoreNames) db.deleteObjectStore(oldstore);
    let posstore=db.createObjectStore("Pos", { keyPath: "id" });
    posstore.createIndex("x", "x");
    posstore.createIndex("y", "y");
    posstore.createIndex("row_name", "row_name");
    posstore.createIndex("col_name", "col_name");
    posstore.createIndex("areapath", "areapath");
    posstore.createIndex("status", "status");
    db.createObjectStore("loginedadmin", { keyPath: "hash" });
}
 /**
  * getTrans
  *
  * @param {{objstore:string[],mode?:string,rebuild?:boolean}} params
  * 
  * @return {IDBTransaction}
  */
export async function getTrans(params) {
    let { objstore, mode = "readonly", rebuild=false} = params;
    const db = await getdb(rebuild);
    return db.transaction(objstore,mode);
}
export const getReqResult = async (req) => new Promise((resovle, reject) => {
    req.onsuccess = resovle;
    req.onerror = reject;
});

export async function find(storename,keyval) {
    let trans = await getTrans({ objstore: [storename] });
    let objstore = trans.objectStore(storename);
    let evt = await getReqResult(objstore.get(keyval));
    return evt.target.result;
}
export async function searchByIndex(storename,key,val,fuzzy,desc) {
    let objstore = await getTrans({ objstore: [storename] }).objectStore(storename);
    let req = objstore.index(key).openCursor(fuzzy?IDBKeyRange.bound(key,key+'\uffff'):IDBKeyRange.only(val),desc?"prev":"next");
    let evt = await getReqResult(req);
    return evt.target.result;
}

export async function searchBykey(storename,keyval,fuzzy,desc) {
    let objstore = await getTrans({ objstore: [storename] }).objectStore(storename);
    let evt = await getReqResult(objstore.openCursor(keyval?(fuzzy?IDBKeyRange.bound(keyval,keyval+'\uffff'):IDBKeyRange.only(val)):"",desc?"prev":"next"));
    return evt.target.result;
}
export async function updateDb(datas) {
    const storenames = Object.keys(datas);
    return new Promise(async (resovle) => {
        let trans = await getTrans({ objstore: storenames, mode: "readwrite" });
        trans.oncomplete = resovle;
        for (let storename in datas) {
            const objstore = trans.objectStore(storename);
            for (let row of datas[storename]) objstore.put(row);
        }
    });
}
/**
 * getAll 返回objectStore中的全部对象
 * @param {string} storename 
 * @returns {object[]}
 */
export async function getAll(storename) {
    let trans = await getTrans({ objstore: [storename] });
    let evt = await getReqResult(trans.objectStore(storename).getAll());
    return evt?evt.target.result:[];
}
export async function del(storename, keyval) {
    let trans = await getTrans({ objstore: [storename],mode:"readwrite" });
    let evt = await getReqResult(trans.objectStore(storename).delete(keyval));
    return evt.target.result;
}