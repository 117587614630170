<template>
    <Page>
            <t-button @click="router.push('/area-add')" variant="text" theme="primary">添加顶级园区</t-button>
            <t-divider />
            <div v-if="store.loading">数据加载中......</div>
            <t-tree v-else ref="tree" :data="areas" :label="getLabel" hover expand-all :expand-on-click-node="false" :expand-parent="true" line :keys="{children:'nodes',value:'key'}">
                <template #operations="{ node:row }">
                    <t-space size="small">
                        <t-button theme="primary" @click="router.push(`/area-add/${row.data.key}`)" variant="text">添加下级</t-button>
                        <t-button theme="warning" @click="router.push(`/area-edit/${row.data.key}`)" variant="text">修改</t-button>
                        <t-button theme="danger" @click="showconfirm = true; store.setArea(row.data.key)" variant="text">删除</t-button>
                    </t-space>
                </template>
            </t-tree>
        <t-dialog v-model:visible="showconfirm" header="确认删除园区" body="真的要删除吗，子园区以及其下的墓位都会一并删除" attach="body" :confirm-on-enter="true" :on-confirm="delarea" />
    </Page>
</template>
<script setup>
import { computed } from 'vue';
import { objectToArray, enums } from '@/utils/funcs';
import { useAreasStore } from '@/store/areas.js';
import { MessagePlugin } from 'tdesign-vue-next';
import { useRouter } from 'vue-router';
const store = useAreasStore()
const tree = ref(null);
const areas = computed(()=>store.areas?objectToArray(store.areas,{sortprop:"ordernum"}) : [])
const router = useRouter();
const showconfirm = ref(false);
const getLabel = (h, node) => {
    return node.data.areaname;
}
async function delarea() {
    showconfirm.value = false;
    const msg = await store.delarea(); 
    if (msg == "ok") MessagePlugin.success("操作成功");
    //else MessagePlugin.error(msg);
}
onMounted(() => {
    store.setArea("");
})
</script>