<template>
  <Page>
    <div class="mb-2 flex justify-between">
      <t-space class="space-x-2">
        <t-button theme="primary" size="small" @click="router.push('/user-edit')">
          <template #icon><t-icon name="add" /></template>
          新增员工
        </t-button>
      </t-space>
    </div>

    <t-table
      size="small"
      :data="usersData"
      row-key="id"
      :columns="columns"
      :pagination="pagination"
      v-model:filterValue="filterValue"
      v-model:displayColumns="displayColumns" 
      :column-controller="{ hideTriggerButton:true  }"
      :loading="loading"
      @filter-change="onFilterChange"
      cell-empty-content="未填" >
      <template #loading>
        <div class="t-table--loading-message">数据加载中</div>
      </template>
      <template #operation="{ row }">
        <t-space>
          <t-link theme="primary" hover="color" @click="router.push('/user-edit/'+row.id)">编辑</t-link>
          <t-link v-if="row.issys!='是'" theme="danger" hover="color" @click="switchUser(row)">{{ row.delete_time?'在职':'离职' }}</t-link>
        </t-space>
      </template>
    </t-table>
  </Page>
</template>

<script setup>
import { MessagePlugin } from 'tdesign-vue-next';
import { reactive, ref } from 'vue'

const router = useRouter()
const request = inject("axios");
const store = useStore();
let cfg;
const usersData = ref([])
const loading = ref(true)
const total = computed(() => usersData.value?.length || 0);
const filterValue = ref({ status: 1, "admin_name": "", "desc.name": "" });
request.get("/SystemSet/usersList").then(({ data }) => {
  console.info("userdata", data);
  if (data.success) {
    cfg = data.data;
    store.$patch({ cfg });
    usersData.value = cfg.users;
    loading.value = false;
    onFilterChange(filterValue.value)
  } 
})


const columns = [
  {
    colKey: 'serial-number',
    title: '序号',
    width: 80
  },
  {
    colKey: 'admin_name',
    title: '用户名',
    filter: {
      type:"input",
      confirmEvents: ['onEnter'],
      showConfirmAndReset: true,
    }
  },
  {
    colKey:"create_time",
    title:"创建时间",
    ellipsis:true,
  },
  {
    colKey: "status",
    title:"员工状态",
    cell:(h,{row})=>row.delete_time?"离职":"在职",
    filter: {
      type: "single",
      list: [
        {label:'全选',checkAll:true},
        { label: "在职",value:1 },
        { label: "离职",value:-1 }
      ],
    }
  },
  {
    colKey:"delete_time",
    title:"停用时间",
  },
  {
    colKey: 'desc.name',
    title: '姓名',
    filter: {
      type:"input",
      confirmEvents: ['onEnter'],
      showConfirmAndReset: true,
    }
  },
  {
    colKey: 'mobile',
    title: '电话',
  },
  {
    colKey: 'roles',
    title: '角色',
    ellipsis:true,
    cell: (h, { col, row }) => row.issys=="是" ? "超级管理员" : store.getRoleNames(row.roles),
  },
  {
    colKey: 'department',
    title: '部门',
    cell:(h,{row})=>store.getDptName(row.desc?.dptid),
  },
  {
    colKey: 'operation',
    title: '操作',
    width: 120
  }
]
const displayColumns = ref( columns.map((v) => v.colKey).filter((v)=>v!='delete_time'))
const pagination = reactive({
  defaultcurrent: 1,
  pageSize: 10,
  total: total,
  showJumper: true
})
function onFilterChange(filters) {
  filterValue.value = { ...filters };
  console.info("filters",filters);
  displayColumns.value = columns.map((v) => v.colKey).filter((v) => v != 'delete_time' || filters.status != 1)
  usersData.value=cfg.users.filter((row) => {
    if (filters.status) {
      switch (filters.status) {
        case 1:
          if (row.delete_time) return false;
          break;
        case -1:
          if (!row.delete_time) return false;
          break;
      }
    }
    if (filters.admin_name &&　!row.admin_name.toLowerCase().includes(filters.admin_name.toLowerCase())) return false;
    if (filters['desc.name'] && !row.desc?.name.toLowerCase().includes(filters["desc.name"].toLowerCase()) ) return false;
    return true;
  })
}
async function switchUser(user) {
  const method = user.delete_time ? "restoreUser" : "delUser";
  const { data } = await request.post(`/SystemSet/${method}/id/${user.id}`);
  if (data.success) {
    usersData.value = cfg.users=data.data;
    onFilterChange(filterValue.value)
    MessagePlugin.success("操作成功");
  }
}
</script>
