import { createApp} from 'vue'
import App from '@/Home.vue'
import { createPinia } from 'pinia'
import * as request from '@/utils/request.js';
import router from '@/router'
import 'tdesign-vue-next/es/style/index.css'
import '@/style.css'
import { useStore } from '@/store'
import Page from '@/layouts/Page.vue'
const app = createApp(App);
const pinia = createPinia();
pinia.use(()=> ({ router:markRaw(router) }));
app.use(pinia);
app.provide("axios",request).component("Page",Page);
pinia.use(() => ({ axios: request }));
const store = useStore()
request.get("/index/getsysinfo").then((res) => {
    const {sysinfo, vars ,msg} = res.data;
    if (!sysinfo) return Promise.reject(msg);
    localStorage.setItem("sysinfo", JSON.stringify(sysinfo));
    localStorage.setItem("vars", JSON.stringify(vars));
    return Promise.resolve();
}).catch((msg)=>alert(msg)).finally(() => {
    const session = sessionStorage.getItem("state");//如果已经登录了就恢复会话状态
    app.use(router).mount('#app')
    request.setStore(pinia);
    if (session) {
        store.$patch(JSON.parse(session));
        store.init();
    } else router.replace("/");
});