<template>
  <div class="grid grid-cols-12">
    <div class="bg-white shadow ring-1 ring-gray-200 px-4 py-3 mb-4 col-span-12">
      <!-- title -->
      <div class="flex justify-between items-center border-b border-solide border-gray-100 pb-2 mb-4">
        <slot name="title">
            <h3 class="text-lg font-bold">{{ title || store.title || store.sidebarActive}}</h3>
            <div class="text-green-600 space-x-2 text-sm">
              <a href="#">常见问题</a>
              <a href="#">帮助文档</a>
            </div>
        </slot>
      </div>

      <!-- tips -->
      <div v-if="tips" class="bg-green-100 p-4 text-green-600 mb-4">
        {{ tips }}
      </div>
    <slot></slot>
    </div>
  </div>
</template>
<script setup>
const store = useStore();
const props=defineProps(['tips','title']);
store.title=props.title
</script>