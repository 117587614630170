import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

import Workbench from '@/pages/workbench.vue'
import Client from '@/pages/client/index.vue'
import ClientEdit from '@/pages/client/ClientEdit.vue'
import ClientInfo from '@/pages/client/ClientInfo.vue'
import Department from '@/pages/personnel/Department.vue'
import Users from '@/pages/personnel/Users.vue'
import UserEdit from '@/pages/personnel/UserEdit.vue'
import Roles from '@/pages/syssetup/Roles.vue'
import RoleEdit from '@/pages/syssetup/RoleEdit.vue'
import AreasList from '@/pages/syssetup/AreasList.vue'
import AreaEdit from '@/pages/syssetup/AreaEdit.vue'
import FeeTypes from '@/pages/syssetup/FeeTypes.vue'
import FeeTypeEdit from '@/pages/syssetup/FeeTypeEdit.vue'
const routes = [
  {
    path: '/',
    //name: '工作台',
    component: Workbench
  },
  {
    path: '/client',
    //name: '客户',
    component: Client
  },
  {
    path: '/client-edit/',
    //name: '新建客户',
    component: ClientEdit
  },
  {
    path: '/client-info/:clientkey?',
    component: ClientInfo,
    props: true
  }, 
  {
    path: '/deptlist',
    component: Department
  },
  {
    path: '/userlist',
    component: Users
  },
  {
    path: '/user-edit/:userkey?',
    component: UserEdit,
    props: true
  },
  {
    path: '/roles',
    //name: '角色列表',
    component: Roles
  },
  {
    path: '/role-edit/:rolekey?',
    component: RoleEdit,
    props: true
  },
  {
    path:'/areas',
    component:AreasList,
  },
  {
    path:'/area-:action(edit|add)/:areacode?',
    component:AreaEdit,
    props:true
  },
  {
    path:'/feetypes',
    component:FeeTypes,
  },
  {
    path:'/feetype-:action(edit|add)/:feetypecode?',
    component:FeeTypeEdit,
    props:true
  },
]

export default createRouter({
  history: createWebHistory(),
  routes
})
