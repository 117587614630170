<template>
<Page :title="title">
    <t-form :data="area" :rules="rules" :colon="true" @submit="store.saveArea(fullkey)" ref="form" >
      <t-form-item label="上级园区">
        <h5>{{ areaname(action=="edit"?area.pkey:fullkey) }}</h5>
      </t-form-item>
      <t-form-item label="园区编码" name="areacode" help="仅包含英文字母与数字，可以有连字符，长度小于20">
        <t-input v-model="area.areacode" :style="{ width: '256px' }" :maxlength="20" show-limit-number></t-input>
      </t-form-item>

      <t-form-item label="园区名称" name="areaname">
        <t-input v-model="area.areaname" :style="{ width: '256px' }" :maxlength="10" show-limit-number></t-input>
      </t-form-item>

      <t-form-item label="行对齐" name="rowalign">
        <t-radio-group :options="alignOption" v-model="area.rowalign"></t-radio-group>
      </t-form-item>

      <t-form-item label="行排序" name="roworder">
        <t-radio-group :options="orderOption" v-model="area.roworder"></t-radio-group>
      </t-form-item>

      <t-form-item label="列排序" name="colorder">
        <t-radio-group :options="orderOption" v-model="area.colorder"></t-radio-group>
      </t-form-item>

      <t-form-item label="排序值" name="ordernum">
        <t-input-number v-model="area.ordernum" :min="0" :default-value="0" />
      </t-form-item>

      <t-form-item label="园区图" name="pics">
        <Uploadimg  v-model="area.pics" :max="3" theme="image-flow" tips="允许最多上传3张" multiple draggable/>
      </t-form-item>

      <t-form-item label="园区描述" name="desc">
        <t-textarea v-model="area.desc" :autosize="{minRows:3, maxRows: 5 }" />
      </t-form-item>

      <t-form-item>
        <t-space size="small">
          <t-button theme="primary" type="submit">保存</t-button>
          <t-button theme="default" variant="base" @click="router.back()">返回</t-button>
        </t-space>
      </t-form-item>
    </t-form>
</Page>
</template>
<script  setup>
import {useAreasStore} from '@/store/areas.js'
import { map } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted} from 'vue';
import {useRouter} from 'vue-router'
import Uploadimg from '@/utils/Upload.vue';
const form = ref(null);
const store = useAreasStore();
const { areaprop:area,areaname,areaarr } = storeToRefs(store);
const router = useRouter();
const props = defineProps(["action", "areacode"]);
const fullkey = computed(() => {
  const a=store.areaarr?.filter(a => a.key == props.areacode);
  return a && a.length>0?a.pop().fullkey:"";
});
onMounted(() => {
  if (props.action == "edit") store.setArea(props.areacode);
  form.value.clearValidate();
})
const title = props.action=="add"?"添加园区":"修改园区";
const alignOption = computed(() => {
  return map(store.enums.alignEnum, (v, k) => ({ label: v, value: k }));
});
const orderOption = computed(() => {
  return map(store.enums.orderEnum, (v, k) => ({ label: v, value: k }));
});
const rules = {
  areacode: [
    { required: true, type: 'error' },
    { pattern: /^[\w\-_]{1,20}$/, type: 'warning' },
    {
      validator: (val) => {
        for (const row of areaarr?.value||[]) {
          if (row.key == val && row.key != props.areacode) return { result: false, message: `编码与园区${row.obj.areaname}相同` }
        }
        return { result: true }
      }
    }
  ],
  areaname: [{ required: true, type: 'error' }],
  rowalign: [{ required: true, type: 'error' }],
  roworder: [{ required: true, type: 'error' }],
  colorder: [{ required: true, type: 'error' }],
  ordernum:[{number:true,type:'error'}]
}
</script>