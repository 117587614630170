import axios from "axios";
import useStore from "../store";
import { MessagePlugin } from "tdesign-vue-next";
// 创建请求实例
const baseURL = APIBASE;
let store;
export const instance = axios.create({
  baseURL,
  // 指定请求超时的毫秒数
  timeout: 0,
  // 表示跨域请求时是否需要使用凭证
  withCredentials: false,
  timeout:0,
  params:{_ajax:1},
});
instance.interceptors.request.use(
  (config) => {
    if (store && store.sid) config.params["sid"] = store.sid;//自动添加会话令牌
    else if (config.params["sid"]) delete config.params["sid"];
    return config;
  },
  (error) => {
    console.log("request fail");
    return Promise.reject(error);
  },
);
instance.interceptors.response.use(
  (response) => {
    const {success, msg } = response.data;
    if (msg == "请重新登录" && store) {//远端会话过期时同时注销本地会话
      store.delSession();
    } 
    if (msg) {
      if(!success) {
        MessagePlugin.error(msg);
        //throw msg;
      }
    }
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.data) {
      return Promise.reject(error);
    }
    const { message } = error;
    console.error(message);
    return Promise.reject(error);
  },
);

// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export const post = async (url, data = {}, params = {},config={}) => {
  return instance({
    method: 'post',
    url,
    data,
    params,
    config,
  });
};

/**
 * 默认导出get方法
 * @param {string} url
 * @param {object} params
 */
export const get=async (url, params = {}) => {
  return instance({
    method: 'get',
    url,
    params,
  });
};

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export const put = (url, data = {}, params = {}) => {
  return instance({
    method: 'put',
    url,
    params,
    data,
  });
};

/**
 * @param {string} url
 * @param {object} params
 */
export const _delete = (url, params = {}) => {
  return instance({
    method: 'delete',
    url,
    params,
  });
};

export const setStore = (pinia) => { if (!store) store = useStore(pinia)};