import { defineStore } from "pinia";
import { inject } from "vue";
import { useRouter } from "vue-router";
import { MessagePlugin } from 'tdesign-vue-next';
import * as utils from '../utils/funcs';

export const useFeeTypeStore = defineStore("feetypes",function(){
    const loading = ref(true);
    const feetypes = ref(null);
    const feetype = ref({});
    const request = inject("axios");
    const router = useRouter();
    const feetypearr = computed(() => {
        return feetypes?.value?utils.flatObj(toRaw(feetypes.value)):[];
    });
    request.get("/SystemSet/feetypeList").then(({ data }) => {
        if (!data.success) return;
        feetypes.value = data.data.feetypes;
        loading.value = false;
    });
    function getFeetype(feetypecode) {
        let row = feetypearr.value.filter((ele)=>ele.key==feetypecode);
        row = row.length ? row.pop() : false;
        return row?{ ...row.obj, key: row.key, pkey: row.pkey, fullkey: row.fullkey }:{};
    }
    function getTypeName(fullpath) {
        if (!fullpath) return "";
        return fullpath.split(".").filter(p => p != "$" && p != 'nodes').map(v => {
            const o = getFeetype(v);
            return o.text;
        }).filter(p => p).join("/");
    }
    async function saveFeeType(pkey) {
        feetype.value.pkey = toRaw(feetype.value.pkey)||pkey;
        const { data:ret } = await request.post(`/SystemSet/editfeetype`, toRaw(feetype.value), { feetypeid: feetype.value.fullkey});
        if (ret.success) {
            MessagePlugin.success("操作成功");
            feetypes.value = ret.data;
            feetype.value = {};
            router.back();
        }
        else {
            MessagePlugin.error(ret.msg);
        }
    };
    async function delFeeType(feetypeid) {
        if (!feetypeid) return "请指定要删除的费用类别";
        const { data:ret } = await request.get(`/SystemSet/delFeeType`,{ feetypeid });
        if (ret.success) {
            feetypes.value = ret.data;
            feetype.value = {};
            return "ok";
        }
        else {
            return ret.msg;
        }
    }
    return { loading, feetypes, feetype, getFeetype, getTypeName,feetypearr,saveFeeType,delFeeType };

})