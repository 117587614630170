<template>
  <div id="add-client" class="bg-white shadow ring-1 ring-gray-200 px-4 py-3">
    <h1 class="font-bold text-lg border-b border-solide border-gray-100 pb-2 mb-4">新增客户</h1>

    <t-form ref="form" reset-type="initial" @submit="onSubmit">
      <t-tabs placement="left" :default-value="1" class="add-client-tab sticky-tab">
        <t-tab-panel :value="1" label="联系人" class="ml-8 mr-4 border">
          <t-tabs v-model="contactPersonTab" theme="card" addable @add="onAddcontactPerson" @remove="removeContactPersonTab">
            <t-tab-panel
              v-for="(contactPerson, index) in formData.contactPersons"
              :key="contactPerson.cid"
              :value="contactPerson.cid"
              :label="contactPerson.label"
              :destroy-on-hide="false"
              :removable="contactPerson.removable">
              <div class="p-4 max-w-2xl">
                <t-form-item label="名字" :name="`contactPersons[${index}].name`">
                  <t-input v-model="formData.contactPersons[index].name"></t-input>
                </t-form-item>
                <t-form-item label="性别" :name="`contactPersons[${index}].gender`">
                  <t-radio-group v-model="formData.contactPersons[index].gender">
                    <t-radio value="male">男</t-radio>
                    <t-radio value="femal">女</t-radio>
                  </t-radio-group>
                </t-form-item>
                <t-form-item label="身份证号" :name="`contactPersons[${index}].idcard`">
                  <t-input v-model="formData.contactPersons[index].idcard"></t-input>
                </t-form-item>
                <t-form-item label="手机号码" :name="`contactPersons[${index}].phone`">
                  <t-input v-model="formData.contactPersons[index].phone"></t-input>
                </t-form-item>
                <p class="mb-4 text-red-600 text-center font-bold">以下是测试页面高度用</p>
                <t-form-item label="名字" :name="`contactPersons[${index}].name`">
                  <t-input v-model="formData.contactPersons[index].name"></t-input>
                </t-form-item>
                <t-form-item label="性别" :name="`contactPersons[${index}].gender`">
                  <t-radio-group v-model="formData.contactPersons[index].gender">
                    <t-radio value="male">男</t-radio>
                    <t-radio value="femal">女</t-radio>
                  </t-radio-group>
                </t-form-item>
                <t-form-item label="身份证号" :name="`contactPersons[${index}].idcard`">
                  <t-input v-model="formData.contactPersons[index].idcard"></t-input>
                </t-form-item>
                <t-form-item label="手机号码" :name="`contactPersons[${index}].phone`">
                  <t-input v-model="formData.contactPersons[index].phone"></t-input>
                </t-form-item>

                <t-form-item label="名字" :name="`contactPersons[${index}].name`">
                  <t-input v-model="formData.contactPersons[index].name"></t-input>
                </t-form-item>
                <t-form-item label="性别" :name="`contactPersons[${index}].gender`">
                  <t-radio-group v-model="formData.contactPersons[index].gender">
                    <t-radio value="male">男</t-radio>
                    <t-radio value="femal">女</t-radio>
                  </t-radio-group>
                </t-form-item>
                <t-form-item label="身份证号" :name="`contactPersons[${index}].idcard`">
                  <t-input v-model="formData.contactPersons[index].idcard"></t-input>
                </t-form-item>
                <t-form-item label="手机号码" :name="`contactPersons[${index}].phone`">
                  <t-input v-model="formData.contactPersons[index].phone"></t-input>
                </t-form-item>

                <t-form-item label="名字" :name="`contactPersons[${index}].name`">
                  <t-input v-model="formData.contactPersons[index].name"></t-input>
                </t-form-item>
                <t-form-item label="性别" :name="`contactPersons[${index}].gender`">
                  <t-radio-group v-model="formData.contactPersons[index].gender">
                    <t-radio value="male">男</t-radio>
                    <t-radio value="femal">女</t-radio>
                  </t-radio-group>
                </t-form-item>
                <t-form-item label="身份证号" :name="`contactPersons[${index}].idcard`">
                  <t-input v-model="formData.contactPersons[index].idcard"></t-input>
                </t-form-item>
                <t-form-item label="手机号码" :name="`contactPersons[${index}].phone`">
                  <t-input v-model="formData.contactPersons[index].phone"></t-input>
                </t-form-item>
              </div>
            </t-tab-panel>
          </t-tabs>
        </t-tab-panel>
        <t-tab-panel :value="2" label="使用人" class="ml-8 mr-4 border">
          <t-tabs v-model="usersTab" theme="card" addable @add="onAddUser" @remove="removeUsersTab">
            <t-tab-panel
              v-for="(user, index) in formData.users"
              :key="user.uid"
              :value="user.uid"
              :label="user.label"
              :destroy-on-hide="false"
              :removable="user.removable">
              <div class="p-4 max-w-2xl">
                <t-form-item label="名字" :name="`users[${index}].name`">
                  <t-input v-model="formData.users[index].name"></t-input>
                </t-form-item>
                <t-form-item label="性别" :name="`users[${index}].gender`">
                  <t-radio-group v-model="formData.users[index].gender">
                    <t-radio value="male">男</t-radio>
                    <t-radio value="femal">女</t-radio>
                  </t-radio-group>
                </t-form-item>
                <t-form-item label="身份证号" :name="`users[${index}].idcard`">
                  <t-input v-model="formData.users[index].idcard"></t-input>
                </t-form-item>
                <t-form-item label="手机号码" :name="`users[${index}].phone`">
                  <t-input v-model="formData.users[index].phone"></t-input>
                </t-form-item>
              </div>
            </t-tab-panel>
          </t-tabs>
        </t-tab-panel>
        <t-tab-panel :value="3" label="商务信息">
          <p style="margin: 20px">选项卡3内容区</p>
        </t-tab-panel>
        <t-tab-panel :value="4" label="会话信息">
          <p style="margin: 20px">选项卡4内容区</p>
        </t-tab-panel>
      </t-tabs>

      <t-form-item>
        <t-space size="small" class="mt-4">
          <t-button theme="primary" type="submit">提交</t-button>
          <t-button theme="default" variant="base" @click="this.$router.back()">返回</t-button>
        </t-space>
      </t-form-item>
    </t-form>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import useStore from '@/store'

let cid = 0
let uid = 0
const getCid = () => {
  return ++cid
}
const getUid = () => {
  return ++uid
}

const contactPersonTab = ref(1)
const usersTab = ref(1)
const formData = reactive({
  contactPersons: [
    {
      cid: getCid(),
      label: '联系人1',
      removable: false,
      name: '',
      gender: '',
      idcard: '',
      phone: ''
    },
    {
      cid: getCid(),
      label: '联系人2',
      removable: true,
      name: '',
      gender: '',
      idcard: '',
      phone: ''
    }
  ],
  users: [
    {
      uid: getUid(),
      label: '使用人1',
      removable: false,
      name: '',
      gender: '',
      idcard: ''
    },
    {
      cid: getUid(),
      label: '使用人2',
      removable: true,
      name: '',
      gender: '',
      idcard: ''
    }
  ]
})

// 取消高亮
const store = useStore()
onMounted(() => {
  store.sidebarActive = ''
})

// 提交
const onSubmit = ({}) => {}

// 添加联系人
const onAddcontactPerson = () => {
  const cid = getCid()
  formData.contactPersons.push({
    cid,
    label: `联系人${cid}`,
    removable: true
  })
  contactPersonTab.cid = cid
}

// 添加使用人
const onAddUser = () => {
  const uid = getUid()
  formData.users.push({
    uid,
    label: `联系人${uid}`,
    removable: true
  })
  usersTab.uid = uid
}

// 移除联系人
const removeContactPersonTab = ({ value: val, index }) => {
  if (index < 0) return false
  formData.contactPersons.splice(index, 1)
  if (formData.contactPersons.length === 0) return
  if (contactPersonTab.cid === val) {
    contactPersonTab.cid = formData.contactPersons[Math.max(index - 1, 0)].cid
  }
}

// 移除使用人
const removeUsersTab = ({ value: val, index }) => {
  if (index < 0) return false
  formData.users.splice(index, 1)
  if (formData.users.length === 0) return
  if (usersTab.uid === val) {
    usersTab.uid = formData.users[Math.max(index - 1, 0)].uid
  }
}
</script>
