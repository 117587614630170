<template>
  <Page :tips="tips">
      <!-- search -->
      <t-form ref="form" :data="formData" :layout="formData.layout" scroll-to-first-error="smooth">
        <t-form-item label="录入时间" name="createTime">
          <t-date-range-picker v-model="formData.createTime" :presets="presets" />
        </t-form-item>

        <t-form-item label="业务员" name="admin">
          <t-input v-model="formData.admin"></t-input>
        </t-form-item>

        <t-form-item label="客户姓名" name="client">
          <t-input v-model="formData.client"></t-input>
        </t-form-item>

        <t-form-item label="逝者姓名" name="dname">
          <t-input v-model="formData.dname"></t-input>
        </t-form-item>

        <t-form-item label="电话号码" name="phone">
          <t-input v-model="formData.phone"></t-input>
        </t-form-item>

        <t-form-item>
          <t-space size="small">
            <t-button theme="primary" type="submit">搜索</t-button>
            <t-button theme="default">高级搜索</t-button>
          </t-space>
        </t-form-item>
      </t-form>

      <!-- table -->
      <div class="mt-8">
        <div class="mb-2 flex justify-between">
          <div class="space-x-2">
            <t-button theme="primary" size="small" @click="$router.push('/client-edit')">
              <template #icon><t-icon name="add" /></template>
              新建
            </t-button>
            <t-button theme="primary" size="small" :disabled="selected"> 转单 </t-button>
            <t-button theme="primary" size="small" :disabled="selected"> 批量短信 </t-button>
            <t-button theme="primary" size="small" :disabled="selected"> 批量报备 </t-button>
            <div class="inline" v-show="!selected">已选中 {{ selecteNum }} 条</div>
          </div>
          <div class="space-x-2">
            <t-button theme="primary" variant="text" size="small">
              <template #icon><t-icon name="setting" /></template>
              定制列
            </t-button>
            <t-button theme="primary" variant="text" size="small">
              <template #icon><t-icon name="download" /></template>
              导出csv
            </t-button>
          </div>
        </div>
        <div class="w-full">
          <t-table
            size="small"
            :data="data"
            :columns="columns"
            :row-key="rowKey"
            :loading="isLoading"
            :pagination="pagination"
            :selected-row-keys="selectedRowKeys"
            table-layout="fixed"
            @cell-click="onCellClick"
            @select-change="onSelectChange">
            <template #operation="slotProps"
              ><t-dropdown :options="options">
                <t-button variant="text">操作</t-button>
              </t-dropdown>
            </template>
          </t-table>
        </div>
      </div>
  </Page>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import useStore from '@/store'
import dayjs from 'dayjs'

const router = useRouter()
const store = useStore()


const tips = ref('【提醒】转单，是指与“购买人”联系的业务员发生变化，新的业务员需要接手“购买人”的联系。')
const isLoading = ref(false)
const selectedRowKeys = ref([])
let selected = ref(true)
let selecteNum = ref(0)

const formData = reactive({
  layout: 'inline',
  createTime: [],
  admin: store.userInfo.name,
  client: '',
  dname: '',
  phone: ''
})

const presets = ref({
  最近7天: [dayjs().subtract(6, 'day'), dayjs()],
  最近3天: [dayjs().subtract(2, 'day'), dayjs()],
  今天: [dayjs(), dayjs()]
})

const data = []

for (let i = 0; i < 20; i++) {
  data.push({
    index: i,
    client: '欧阳娜飞',
    phone: '13800138000',
    'sos-phone': '13212342536',
    user: '欧阳华光',
    department: '销售一部',
    clerk: '张国一男',
    review: '2022-01-01 12:20',
    admin: '陈陈飞飞',
    date: '2022-01-01 12:20'
  })
}

const pagination = reactive({
  current: 1,
  pageSize: 10,
  total: 0,
  showJumper: true,
  onChange: (pageInfo) => {
    console.log('pagination.onChange', pageInfo)
  }
})

const columns = [
  {
    colKey: 'row-select',
    type: 'multiple',
    width: '46'
  },
  {
    colKey: 'serial-number',
    title: '序号',
    width: '46',
    align: 'center'
  },
  {
    colKey: 'client',
    title: '购买人',
    width: '120'
  },
  {
    colKey: 'phone',
    title: '联系电话',
    width: '180'
  },
  {
    colKey: 'sos-phone',
    title: '应急电话',
    width: '180'
  },
  {
    colKey: 'user',
    title: '使用人',
    width: '120'
  },
  {
    colKey: 'department',
    title: '销售部门',
    width: '120'
  },
  {
    colKey: 'clerk',
    title: '业务员',
    width: '120'
  },
  {
    colKey: 'review',
    title: '最近回访时间',
    width: '200'
  },
  {
    colKey: 'admin',
    title: '录入员',
    width: '120'
  },
  {
    colKey: 'date',
    title: '录入时间',
    width: '200'
  },
  {
    colKey: 'operation',
    title: '操作',
    width: 100,
    cell: 'operation',
    fixed: 'right'
  }
]

const options = [
  { content: '详细', value: 1 },
  { content: '联系人+', value: 2 },
  { content: '使用人+', value: 3 },
  { content: '提醒+', value: 4 },
  { content: '短信+', value: 5 },
  { content: '转单', value: 6 },
  { content: '报备', value: 7, divider: true },
  { content: '定金+', value: 8 },
  { content: '合同+', value: 9 },
  { content: '配套+', value: 10 },
  { content: '存坛+', value: 11, divider: true },
  { content: '作废', value: 12 }
]

const onSelectChange = (value, params) => {
  selectedRowKeys.value = value
  console.log(value, params)
  if (value.length > 0) {
    selected = false
    selecteNum = value.length
  } else {
    selected = true
  }
}

const rowKey = 'index'

const onCellClick = value => {
  if(value.col.colKey != 'operation') {
    router.push('/client-info/' + value.row.admin)
  }
}
</script>
