<template>
  <t-layout>
    <!-- HEADER -->
    <Header />

    <t-layout>
      <!-- SIDEBAR -->
      <t-aside class="shadow w-auto">
        <Aside />
      </t-aside>

      <t-layout>
        <!-- MAIN -->
        <t-content class="p-4 router-view mt-12">
          <router-view />
        </t-content>

        <!-- FOOTER -->
        <t-footer>{{ copyright }}</t-footer>
      </t-layout>
    </t-layout>
  </t-layout>
</template>

<script setup>
import {ref} from 'vue'
import Header from '@/layouts/Header.vue'
import Aside from '@/layouts/Aside.vue'
import { useStore } from '@/store'
const store = useStore();
const copyright = ref(store.vars.copyright?store.vars.copyright:'重庆天蚕网络科技有限公司开发 服务电话：023-63612462 地址：重庆市渝中区上清寺美专校街66号B28-8 官网：www.tiancan.net')
const router = useRouter();
</script>

<style>
.router-view {
  min-height: calc(100vh - 48px - 70px);
}
</style>
