import { map,orderBy } from "lodash";
import { toRaw } from "vue";
/**
 * 根据keypath从服务器返回的树形结构数据返回子节点
 * @param {array} keypath //顺序从上到下
 * @param {object} tree 
 * @returns {array}
 */
export function getNodeChildren(keypath, tree) {
    const children=keypath.reduce((pv, cv, i) => {
        return pv[cv].nodes ?? {};
    }, tree);
    const arr = [];
    for (const key in children) {
        if (Object.hasOwnProperty.call(children, key)) {
            children[key]["value"]=key;
            children[key]["children"]=true;
            arr.push(children[key]);
        }
    }
    return arr;
}
/**
 * 
 * @param {TreeNodeModel} node 
 * @returns {string}
 */
export function getFullPath(node) {
    return map(node.getPath(),(itemnode, index) => (index === 0 ? "$." : "nodes.") + itemnode.value).join(".");
}

export function getTreeData(tree) {
    const datawalk = (v, k) => {
        const data = { ...v };
        if (data.nodes) {
            data.children = map(data.nodes, datawalk);
            delete data.nodes;
        }
        data.value = k;
        return data;
    }
    const data=map(tree, datawalk);
    return data;
}

/**
 * 根据Cascade选项值返回fullpath
 * @param {array} cv 
 * @returns {string}
 */
export function cascadevalToFullpath(cv) {
    return map(cv??[],(v, i) => (i == 0 ? '$.' : 'nodes.') + v).join(".");
}

/**
 * 将服务器返回的树状对向转化为数据并可选的进行排序
 * @param {object} obj 
 * @param {{sortprop:string,sortasc:boolean}} [options={}] 
 * @returns {string}
 */
export function objectToArray(obj, options) {
    const { sortprop, sortasc=true } = (options || {});
    let arr = obj?map(obj, (v, k) => {
        const ele = {...v};
        ele.key = k;
        if (ele.nodes) ele.nodes = objectToArray(ele.nodes,options);
        return ele;
    }):[];
    if (sortprop) {
        arr = orderBy(arr, sortprop, sortasc ? "asc" : "desc");
    }
    return arr;
}

/**
 * 将服务器返回的树状对象扁平化以方便查询
 * @param {object} obj 
 * @param {string} pkey? 
 * @returns {{key:string,pkey:string,fullkey:string,obj:object}[]}
 */
export function flatObj(obj,pkey) {
    if (!pkey) pkey = "$";
    let keys = [],fullkey;
    for (let key in obj) {
        fullkey = `${pkey}.${key}`;
        keys.push({ key,pkey:pkey.replace(/\.nodes$/,""),fullkey,obj:obj[key]});
        if (obj[key]?.nodes) keys = keys.concat(flatObj(obj[key].nodes,`${pkey}.${key}.nodes`));
    }
    return keys;
}

/**
 * 
 * @param {Proxy} o 
 * @returns boolean
 */
export function isEmptyObj(o) {
    const obj = toRaw(o);
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const enums = {
    orderEnum:{asc:"升序",desc:"降序"},
    alignEnum:{left:"左对齐",right:"右对齐","center":"居中对齐"},
}