<template>
  <Page :title="userkey ? '编辑员工信息' : '添加员工'">
    <t-form :data="userFormData" :rules="USER_RULES" @submit="saveData" >
      <t-form-item label="用户名" name="admin_name">
        <t-input v-model.trim="userFormData.admin_name" :style="{ width: '256px' }" :maxlength="20" show-limit-number></t-input>
      </t-form-item>

      <t-form-item label="用户密码" name="pwd" :help="userkey?'不修改可不填写':''">
        <t-input v-model.trim="userFormData.pwd" :style="{ width: '256px' }" :maxlength="20" show-limit-number></t-input>
      </t-form-item>

      <t-form-item label="员工姓名" name="pname">
        <t-input v-model.trim="userFormData.pname" :style="{ width: '256px' }"></t-input>
      </t-form-item>

      <t-form-item label="员工手机号" name="mobile">
        <t-input v-model.trim="userFormData.mobile" :style="{ width: '256px' }"></t-input>
      </t-form-item>

      <t-form-item label="所属部门" name="dptkey" v-if="userFormData.issys!='是'">
        <t-cascader :keys="{label:'dptname'}" :options="depOptions" value-type="full" v-model="userFormData.dptkey" filterable clearable check-strictly></t-cascader>
      </t-form-item>

      <t-form-item label="角色设置" name="roles" v-if="userFormData.issys!='是'">
        <t-checkbox-group v-model="userFormData.roles" :options="rolesOptions" />
      </t-form-item>

      <t-form-item>
        <t-space size="small">
          <t-button theme="primary" type="submit">保存</t-button>
          <t-button theme="default" variant="base" @click="router.back()">返回</t-button>
        </t-space>
      </t-form-item>
    </t-form>
  </Page>
</template>

<script setup>
import { getTreeData,cascadevalToFullpath } from '@/utils/funcs';
import { map } from 'lodash';
import { MessagePlugin } from 'tdesign-vue-next';
const props = defineProps(['userkey']);
const router = useRouter();
const store = useStore()
const cfg = store.cfg;
const request = inject("axios");
// 用户表单数据初始化
const initdata = {
  admin_name: '',
  pwd: '',
  mobile: '',
  roles: [],
  pname: '',
  dptkey: '',
};
const userFormData = ref({
  ...initdata
})
if (props.userkey) {
  const userobj = toRaw(cfg.users.filter((row) => row.id == props.userkey)?.[0]);
  if (userobj) {
    const  desc  = userobj.desc;
    if (desc) {
      userobj.dptkey = desc.dptid.split(".").filter(v => v != '$' && v != 'nodes');
      userobj.pname = desc.name;
    }
    userobj.pwd = "";
    delete userobj.desc;
    userFormData.value = Object.assign({},userobj,desc);
  }
  else {
    MessagePlugin.error("用户没有找到");
    router.back();
  }
}

// 用户表单规则
const USER_RULES = {
  admin_name: [{ required: true, type: 'error' },{
    validator: (v) => {
      const l = cfg.users.filter((u) => u.admin_name == v && u.id != props.userkey).length;
      return l == 0
    }, message: '账号与其它员工重复'
    }],
  pname: [{ required: true, type: 'error' }],
  mobile: [{ required: true, type: 'error' }, { telnumber: true }, {
    validator: (v) => {
      const l = cfg.users.filter((u) => u.mobile == v && u.id != props.userkey).length;
      return l == 0
    }, message: '电话号码与其它员工重复'
    }],
  dptkey: [{ required: userFormData.issys!="是" }],
  pwd: [{ required: !props.userkey }],
  roles:[{required:userFormData.issys!="是"}]
}

// 部门列表
const depOptions = getTreeData(cfg.dpts);
// 角色列表
const rolesOptions = [
  { label: '全选', checkAll: true },
  ...map(cfg.roles,(v, k) =>({ label: v.rolename, value: k }))
]

const saveData = async ({validateResult}) => {
  if (validateResult === true) {
    const url=props.userkey ?("/SystemSet/editUser/id/" + props.userkey):"/SystemSet/addUser"
    const postdata = Object.assign({}, userFormData.value);
    postdata.dptkey = cascadevalToFullpath(postdata.dptkey);
    const { data } = await request.post(url, postdata);
    if (data.success) {
      cfg.users = data.data;
      userFormData.value = { ...initdata };
      MessagePlugin.success("操作成功");
      router.back();
    }
  }
}
</script>
