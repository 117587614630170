<template>
    <Page>
        <t-button @click="router.push('/feetype-add')" variant="text" theme="primary">添加顶级费用类别</t-button>
        <t-divider />
        <div v-if="store.loading">数据加载中......</div>
        <t-tree v-else ref="tree" :data="feetypes" hover expand-all :expand-on-click-node="false" :expand-parent="true" :label="true" line :keys="{ children: 'nodes', value: 'key',label:'text' }">
            <template #operations="{ node: row }">
                <t-space size="small">
                    <t-button theme="primary" @click="router.push(`/feetype-add/${row.data.key}`)" variant="text">添加下级</t-button>
                    <t-button theme="warning" @click="router.push(`/feetype-edit/${row.data.key}`)" variant="text">修改</t-button>
                    <t-button theme="danger" @click="showconfirm = true;feetype=row.data.key" variant="text">删除</t-button>
                </t-space>
            </template>
        </t-tree>
        <t-dialog v-model:visible="showconfirm" header="确认删除费用" body="真的要删除吗，其下的费用都会一并删除" attach="body" :confirm-on-enter="true" :on-confirm="delFeeType" />
    </Page>
</template>
<script setup>
import { useFeeTypeStore } from "@/store/feetypes";
import { objectToArray } from "@/utils/funcs";
import { computed } from "vue";
import { MessagePlugin } from 'tdesign-vue-next';
const store = useFeeTypeStore();
const feetypes = computed(() => store.feetypes ? objectToArray(store.feetypes) : []);
const router = useRouter();
const feetype = ref(null);
const showconfirm = ref(false);
async function delFeeType() {
    showconfirm.value = false;
    const msg = await store.delFeeType(feetype.value); 
    if (msg == "ok") MessagePlugin.success("操作成功");
}
</script>