<template>
  <div id="login-page" class="w-screen h-screen">
    <div class="absolute top-[22%] left-[5%] w-[400px]">
      <h1 class="text-4xl font-bold mb-12">登录到<br />陵园系统V6.0</h1>
      <t-form
        :class="[activeForm == 'login' ? 'block' : 'hidden']"
        ref="form"
        :data="formData"
        :label-width="0"
        @submit="onSubmit"
        :rules="valirules">
        <t-form-item name="account" label="用户">
          <t-input v-model="formData.account" size="large" clearable placeholder="请输入账户名">
            <template #prefix-icon>
              <user-icon />
            </template>
          </t-input>
        </t-form-item>

        <t-form-item name="password" label="密码">
          <t-input v-model="formData.password" size="large" type="password" clearable placeholder="请输入密码">
            <template #prefix-icon>
              <lock-on-icon />
            </template>
          </t-input>
        </t-form-item>

        <div class="flex justify-between items-center">
          <t-checkbox v-model="remember">记住账户名</t-checkbox>
          <span class="text-primary cursor-pointer hover:text-primary-dark" @click="setActiveForm('phone')">忘记密码？使用手机号登录</span>
        </div>

        <t-form-item>
          <t-button type="submit" size="large" block class="mt-12">登录</t-button>
        </t-form-item>
      </t-form>

      <t-form
        :class="[activeForm == 'phone' ? 'block' : 'hidden']"
        ref="form"
        :data="forgetData"
        :label-width="0"
        @submit="onSubmit"
        :rules="forgetRules">
        <t-form-item name="account" label="手机号">
          <t-input v-model="forgetData.account" type="tel" size="large" clearable placeholder="请输入手机号">
            <template #prefix-icon>
              <user-icon />
            </template>
          </t-input>
        </t-form-item>

        <t-form-item name="verification" label="验证码">
          <t-input v-model="forgetData.verification" size="large" clearable placeholder="请输入验证码"> </t-input>
          <t-button type="button" @click="sendcode" size="large" class="ml-2">{{ sendCodeText }}</t-button>
        </t-form-item>

        <div class="flex justify-between items-center">
          <t-checkbox v-model="remember">记住账户名</t-checkbox>
          <span class="text-primary cursor-pointer hover:text-primary-dark" @click="setActiveForm('login')">返回使用密码登录</span>
        </div>

        <t-form-item>
          <t-button type="submit" size="large" block class="mt-12">登录</t-button>
        </t-form-item>
      </t-form>
    </div>

    <div id="cover" class="absolute w-[48vw] h-[98vh] top-[1vh] right-[1vh] rounded-lg"></div>

    <div class="copyright absolute bottom-16 left-[5%] text-gray-400 text-sm font-sm">
      Copyright @ 2006-{{ dayjs().year() }} 天蚕网络科技有限公司. All Rights Reserved
    </div>
  </div>
</template>

<script setup>
import { MessagePlugin } from 'tdesign-vue-next'
import dayjs from 'dayjs'
import { reactive } from 'vue'
const request = inject("axios");
const store = useStore()

const sendCodeText = ref('发送验证码')
const activeForm = ref('login')
const remember = ref(false)

const formData = reactive({
  account: '',
  password: ''
})

const forgetData = reactive({
  account: '',
  verification: ''
})

const valirules = { account: [{ required: true, message: '请输入账户名' }], password: [{ required: true, message: '请输入密码' }] }

const forgetRules = { account: [{ required: true, message: '请输入手机号' },{telnumber:true,message:"请输入正确的手机号"}], verification: [{ required: true, message: '请输入验证码' }] }

const setActiveForm = (str) => {
  activeForm.value = str
}

const onSubmit = async ({ validateResult, firstError }) => {
  if (validateResult === true) {
    const param = activeForm.value != "phone" ? [formData.account, formData.password, false] : [forgetData.account, forgetData.verification, true];
    const result = await store.login(...param);
    if (remember.value) {
      if (formData.account != '') {
        localStorage.setItem('account', formData.account)
      } else if (forgetData.account != '') {
        localStorage.setItem('account', forgetData.account)
      }
    } else {
      localStorage.setItem('account', '')
    }
  } else {
    console.log('Validate Errors: ', firstError, validateResult)
    MessagePlugin.warning(firstError)
  }
}
async function sendcode() {
  const tel = forgetData.account;
  if (!/^1[3-9]\d{9}$/.test(tel)) {
    MessagePlugin.error("请输入正确的手机号");
    return;
  }
  const result = await request.get("/Index/sendcode", { tel });
  if (result.data.success) {
    store.sid = result.data.data.sid;
    MessagePlugin.success("发送成功");
  } 
}

onMounted(() => {
  let rememberAccount = localStorage.getItem('account')
  if (rememberAccount) {
    formData.account = rememberAccount
    forgetData.account = rememberAccount
    remember.value = true
  }
})
</script>

<style scoped>
#login-page {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

#cover {
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
}
</style>
