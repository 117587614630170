import { defineStore } from 'pinia';
import { computed,toRaw } from 'vue';
import * as utils from '../utils/funcs';
import { MessagePlugin } from 'tdesign-vue-next';
import * as request from '@/utils/request.js';
import { useRouter } from 'vue-router';
export const useAreasStore = defineStore("areas", function(){
    const areas = ref(null);
    const area = ref({});
    const loading = ref(true);
    const areacode = ref(null);
    const router = useRouter();
    const areaarr = computed(() => {
        return areas?.value?utils.flatObj(toRaw(areas.value)):[];
    });
    function getArea(areacode) {
        let row = areaarr.value.filter((ele)=>ele.key==areacode);
        row = row.length ? row.pop() : false;
        return row?{ ...row.obj, key: row.key, pkey: row.pkey, fullkey: row.fullkey }:{};
    }
    function setArea(code) {
        areacode.value = code;
    }
    request.get("/SystemSet/areaList").then(({ data }) => {
        console.info("areadata", data)
        if (!data.success) return
        areas.value = data.data;
        loading.value = false;
    })
    const areaprop = computed({
        get() {
            //if (!utils.isEmptyObj(area.value)) return area.value;
            if (areacode.value && areas.value) area.value = getArea(areacode.value);
            else area.value = {};
            return area.value;
        },
        set(areavalue) {
            area.value = areavalue;
        }
    });
    const areaname = computed(() => {
        return (fullpath) => {
            if (!fullpath) return "";
            return fullpath.split(".").filter(p => p != "$" && p != 'nodes').map(v => {
                const o = getArea(v);
                return o.areaname;
            }).filter(p => p).join("/");
        }
    });
    async function saveArea(pkey) {
        area.value.pkey = toRaw(area.value.pkey)||pkey;
        area.value.pics = (area.value.pics||[]).filter((pic) => pic.status == "success");
        area.value.pics = toRaw(area.value.pics);
        area.value.pics.forEach((pic) => { delete pic.response; delete pic.percent; delete pic.raw; })
        const { data:ret } = await request.post(`/SystemSet/editArea`, toRaw(area.value), { areaid: area.value.fullkey});
        if (ret.success) {
            MessagePlugin.success("操作成功");
            areas.value = ret.data;
            area.value = {};
            router.back();
        }
        else {
            //MessagePlugin.error(ret.msg);
        }
    };
    async function delarea() {
        if (!areacode.value) return "请指定要删除的园区";
        const { data:ret } = await request.get(`/SystemSet/delArea`,{ areaid: toRaw(areacode.value)});
        if (ret.success) {
            areas.value = ret.data;
            area.value = {};
            return "ok";
        }
        else {
            return ret.msg;
        }
    }
    return { areas,areaprop,enums:utils.enums,areaarr,loading,getArea,setArea,areaname,saveArea,delarea};
})
