<template>
  <t-header class="flex bg-white shadow h-12 pr-6 items-center z-20 text-black text-opacity-60 fixed w-full">
    <!-- sidebar switch button -->
    <div
      class="sidebar__switch__button h-full w-12 bg-green-700 flex items-center justify-center cursor-pointer hover:bg-green-600 text-white transition-all"
      @click="changeCollapsed()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="h-10 w-auto mx-auto">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>
    </div>

    <!-- logo -->
    <div class="header__logo flex items-center mx-4">
      <img class="logo h-8 mr-2" src="@/assets/logo.png" alt="logo" />
      <h1 class="font-bold text-green-600 text-xl">{{ logo.text }}</h1>
    </div>

    <!-- search -->
    <div class="header__search mx-auto">
      <t-input type="search" clearable placeholder="请输入搜索内容">
        <template #prefix-icon>
          <SearchIcon />
        </template>
      </t-input>
    </div>

    <div class="header__operations flex text-sm items-center">
      <a
        class="hover:bg-gray-100 rounded transition-all px-2 py-1 text-black text-opacity-60"
        :href="item.url"
        v-for="(item, key) in operations"
        :key="key">
        {{ item.text }}
      </a>

      <a href="#" class="hover:bg-gray-100 rounded text-black text-opacity-60 transition-all px-2 py-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
        </svg>
      </a>

      <a href="#" class="hover:bg-gray-100 rounded text-black text-opacity-60 transition-all px-2 py-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
        </svg>
      </a>

      <a href="#" class="hover:bg-gray-100 rounded text-black text-opacity-60 transition-all px-2 py-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
        </svg>
      </a>

      <a href="#" class="hover:bg-gray-100 rounded text-black text-opacity-60 transition-all px-2 py-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
        </svg>
      </a>

      <a href="#" class="hover:bg-gray-100 rounded text-black text-opacity-60 transition-all px-2 py-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.348 14.651a3.75 3.75 0 010-5.303m5.304 0a3.75 3.75 0 010 5.303m-7.425 2.122a6.75 6.75 0 010-9.546m9.546 0a6.75 6.75 0 010 9.546M5.106 18.894c-3.808-3.808-3.808-9.98 0-13.789m13.788 0c3.808 3.808 3.808 9.981 0 13.79M12 12h.008v.007H12V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
        </svg>
      </a>

      <div class="flex flex-col justify-center text-right h-12 mr-4 text-xs border-l pl-8 ml-6 leading-normal">
        <span>{{ store.userInfo.phone.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2') }}</span>
        <span>账号：{{ store.userInfo.name }}</span>
      </div>
      <t-avatar :image="store.userInfo.avatar"></t-avatar>
    </div>
  </t-header>
</template>

<script setup>
import useStore from '@/store'
import { reactive } from 'vue'

const store = useStore()

const logo = reactive({
  url: '',
  text: '陵园系统V6.0'
})

// 远端获取的用户信息，存入全局属性
/*let getApiUserInfo = {
  phone: '13000000000',
  name: '张三',
  avatar: 'https://tdesign.gtimg.com/site/avatar.jpg'
}

store.userInfo.name = getApiUserInfo.name
store.userInfo.phone = getApiUserInfo.phone
store.userInfo.avatar = getApiUserInfo.avatar*/

const operations = reactive([
  {
    url: '#',
    text: '墓位图'
  },
  {
    url: '#',
    text: '存坛图'
  },
  {
    url: '#',
    text: '短信'
  },
  {
    url: '#',
    text: '工单'
  }
])

const changeCollapsed = () => {
  store.collapsed = !store.collapsed
}
</script>

<style>
.t-layout__header .t-head-menu__inner,
.t-layout__header .t-menu__operations {
  @apply h-12 !important;
}
</style>
