<template>
  <Page :title="rolekey ? '编辑角色' : '添加角色'">
    <t-form :data="role" :rules="rules" @submit="saveData" >
      <t-form-item label="角色编码" name="rolecode" help="仅包含英文字母与数字，可以有连字符，长度小于20">
        <t-input v-model="role.rolecode" :style="{ width: '256px' }" :maxlength="20" show-limit-number></t-input>
      </t-form-item>

      <t-form-item label="角色名称" name="rolename">
        <t-input v-model="role.rolename" :style="{ width: '256px' }" :maxlength="10" show-limit-number></t-input>
      </t-form-item>

      <t-form-item :label="obj.menuname" v-for="(obj, key) in sysperms">
        <t-checkbox-group v-model="role.perms[key]">
          <t-checkbox check-all label="全选" />
          <template v-for="(permname, permkey) in obj.perms">
            <t-checkbox :value="permkey">{{ permname }}</t-checkbox>
          </template>
        </t-checkbox-group>
      </t-form-item>

      <t-form-item label="菜单设置" name="menus">
        <t-cascader multiple :options="menuoptions" v-model="role.menus" value-type="full" clearable></t-cascader>
      </t-form-item>
      
      <t-form-item>
        <t-space size="small">
          <t-button theme="primary" type="submit">保存</t-button>
          <t-button theme="default" variant="base" @click="this.$router.back()">返回</t-button>
        </t-space>
      </t-form-item>
    </t-form>
  </Page>
</template>
<script setup>
import { mapValues, pickBy } from 'lodash'

const store = useStore()
const props = defineProps(['rolekey'])
const rolekey = props.rolekey
const { allperms, perms, menus, roles } = store.cfg
let initrole = { rolecode: '', rolename: '', perms: {}, menus: [] }

if (rolekey) initrole = Object.assign(initrole, roles.find((r) => r.rolecode == rolekey) ?? {})

const role = reactive(initrole)

const sysperms = mapValues(perms, (val, key) => {
  return {
    menuname: allperms[key].menuname,
    perms: pickBy(allperms[key].perms, (permname, permkey) => val.includes(permkey))
  }
})

for (const permkey in allperms) {
  if (!role.perms[permkey]) role.perms[permkey] = []
}

if (!role.menus) role.menus = []
const menuoptions = computed(() => {
  const options = []
  for (const menu of menus) {
    const obj = {
      label: menu.name,
      value: menu.name
    }
    if (menu.child) {
      obj.children = []
      menu.child.forEach((c) => obj.children.push({ label: c.name, value: c.name }))
    }
    options.push(obj)
  }
  return options
})

const rules = {
  rolecode: [
    { required: true, type: 'error' },
    { pattern: /^[\w\-_]{1,20}$/, type: 'warning' },
    {
      validator: (val) => {
        for (const role of roles) {
          if (role.rolecode == val && role.rolecode != rolekey) return { result: false, message: `编码与角色${role.rolename}相同` }
        }
        return { result: true }
      }
    }
  ],
  rolename: [{ required: true, type: 'error' }]
}

function saveData({ validateResult }) {
  if (validateResult === true) store.saveRole(rolekey, role)
}
</script>
