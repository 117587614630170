<template>
  <Page>
    <div class="mb-2 flex justify-between">
      <t-space class="space-x-2">
        <t-button theme="primary" size="small" @click="router.push('/role-edit')">
          <template #icon><t-icon name="add" /></template>
          新增角色
        </t-button>
      </t-space>
    </div>
    <t-table
      size="small"
      :data="roles"
      row-key="rolecode"
      :columns="columns"
      :pagination="pagination"
      :loading="loading"
      @data-change="dataChange">
      <template #perms="{ row }">
        <span v-for="(perms, key) in row.perms"> {{ getPermTxt(key, perms) }}</span>
      </template>
      <template #menus="{ row }">
        {{ getsubmenutxt(row.menus ?? []) }}
      </template>
      <template #operation="{ row }">
        <t-space>
          <t-link theme="primary" hover="color" @click="editrole(row.rolecode)">编辑</t-link>
          <t-link theme="danger" hover="color" @click="delrole(row.rolecode)">删除</t-link>
        </t-space>
      </template>
    </t-table>
  </Page>
</template>

<script lang="jsx" setup>
import { DialogPlugin } from 'tdesign-vue-next'
import { mapValues } from 'lodash'
import { ref } from 'vue'

const request = inject('axios')
const store = useStore()
const router = useRouter()

const roles = ref([])
const loading = ref(true)
let cfg

request.get('/SystemSet/rolesList').then(({ data }) => {
  console.log(data)
  if (!data.success) return
  cfg = data.data
  store.$patch({ cfg })
  roles.value = cfg.roles
  loading.value = false
  pagination.total = cfg.roles.length
})

const columns = [
  {
    colKey: 'rolecode',
    title: '角色代码',
    width: 100
  },
  {
    colKey: 'rolename',
    title: '角色名',
    width: 120
  },
  {
    colKey: 'perms',
    title: 'API权限',
    ellipsis: true
  },
  {
    colKey: 'menus',
    title: '菜单',
    ellipsis: true
  },
  {
    colKey: 'operation',
    title: '操作',
    width: 120
  }
]
const pagination = reactive({
  defaultcurrent: 1,
  pageSize: 10,
  total: roles.value.length,
  showJumper: true
})

const dataChange = (data) => {
  console.log('data change' + data)
  //roles.value = data;
}

/**
 * 返回权限列表对象的字符串表示
 * @param {string} permkey
 * @param {array} items
 * @returns {string}
 */
const getPermTxt = (permkey, items) => {
  if (!cfg) return ''
  const allperms = cfg.allperms
  if (!allperms[permkey]) return ''
  const permstr = allperms[permkey].menuname
  const itemstr = items.map((val) => allperms[permkey]['perms'][val]).join(',')
  return permstr ? `${permstr}(${itemstr});` : ''
}
/**
 *
 * @param {array} submenus
 * @returns {string}
 */
function getsubmenutxt(submenus) {
  const menuobj = {}
  submenus.map((ele) => {
    const [lv1, lv2] = ele
    if (!menuobj[lv1]) menuobj[lv1] = []
    if (lv2) menuobj[lv1].push(lv2)
  })
  const arr = Object.values(
    mapValues(menuobj, (v, k) => {
      return k + (v.length > 0 ? '(' + v.join(',') + ')' : '')
    })
  )
  return arr.join(';')
}

function delrole(rolekey) {
  const confirmDia = DialogPlugin({
    header: '确认删除当前所选角色？',
    body: '删除后，角色将被清空，且无法恢复。',
    confirmBtn: '确认',
    cancelBtn: '取消',
    onConfirm: ({ e }) => {
      request.get('/SystemSet/delRole', { roleid: rolekey }).then(({ data }) => {
        if (data.success) {
          let initdata = []
          const newrole = data.data
          for (let key in newrole) {
            initdata.push({ ...newrole[key], key })
          }
          roles.value = [...initdata]
        }
      })
      confirmDia.hide()
    },
    onClose: ({ e, trigger }) => {
      confirmDia.hide()
    }
  })
}

function editrole(rolekey) {
  router.push('/role-edit/' + rolekey)
}
</script>
