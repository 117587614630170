<template>
  <div id="workbench" class="grid grid-cols-12 gap-4">
    <!-- left box -->
    <div class="col-span-8">
      <!-- 待办提醒 -->
      <div class="bg-white shadow ring-1 ring-gray-200 px-4 py-3 mb-4">
        <h3 class="mb-4 text-lg font-bold">待办提醒</h3>
        <div class="grid grid-cols-5">
          <div class="flex flex-col" v-for="(item, key) in todoList" :key="item.title">
            <template v-if="key < 5">
              <span class="mb-2">{{ item.title }}</span>
              <span class="font-bold text-green-700">{{ item.content }}</span>
            </template>
          </div>
        </div>
        <t-divider></t-divider>
        <div class="grid grid-cols-5">
          <div class="flex flex-col" v-for="(item, key) in todoList" :key="item.title">
            <template v-if="key >= 5">
              <span class="mb-2">{{ item.title }}</span>
              <span class="font-bold text-green-700">{{ item.content }}</span>
            </template>
          </div>
        </div>
      </div>

      <!-- 表格 -->
      <div class="bg-white shadow ring-1 ring-gray-200 px-4 py-3">
        <div class="flex justify-between items-center mb-4">
          <t-radio-group default-value="2">
            <t-radio-button value="1">定金到期提醒</t-radio-button>
            <t-radio-button value="2">管理费到期提醒</t-radio-button>
            <t-radio-button value="3">待安葬提醒</t-radio-button>
            <t-radio-button value="4">存坛到期提醒</t-radio-button>
          </t-radio-group>
        </div>
        <t-table row-key="index" :data="data" :columns="columns" table-layout="fixed" table-content-width="100%" bordered resizable>
          <template #operation="slotProps">
            <a class="link text-green-700 cursor-pointer hover:text-green-500 transition-all" @click="rehandleClickOp(slotProps)"
              >查看详情</a
            >
          </template>
        </t-table>
        <div class="text-green-600 hover:text-green-700 cursor-pointer mt-4">查看更多</div>
      </div>
    </div>

    <!-- right box -->
    <div class="col-span-4">
      <!-- 优惠活动 -->
      <div class="bg-white shadow ring-1 ring-gray-200 px-4 py-3 mb-4">
        <h3 class="mb-4 text-lg font-bold">优惠活动</h3>
        <div class="space-y-2">
          <template v-for="(item, key) in promotions" :key="item.title">
            <t-link :href="item.url" theme="default" underline> {{ item.title }} </t-link>
          </template>
        </div>
        <t-link theme="success" class="mt-6">查看更多</t-link>
      </div>

      <div class="bg-white shadow ring-1 ring-gray-200 px-4 py-3">
        <h3 class="mb-4 text-lg font-bold">帮助文档</h3>

        <t-tabs v-model="value">
          <template v-for="(item, key) in doc" :key="key">
            <t-tab-panel :value="key" :label="item.title" :destroy-on-hide="false">
              <div class="grid grid-cols-2 justify-between mt-4 gap-4">
                <div v-for="(postsItem, postsKey) in item.posts" :key="postsItem.title">
                  <t-button theme="default" variant="text">{{ postsItem.title }}</t-button>
                </div>
              </div>
              <t-link :href="item.moreUrl" theme="success" class="mt-6">查看更多</t-link>
            </t-tab-panel>
          </template>
        </t-tabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import useStore from '@/store'

// 边栏导航高亮
const store = useStore()

onMounted(() => {
  store.sidebarActive = '工作台'
})

const value = ref(0)

const data = []
const promotions = []
const doc = [
  {
    title: '实践操作文档',
    posts: [
      {
        title: '购买人添加介绍',
        url: '#'
      },
      {
        title: '逝者信息添加',
        url: '#'
      },
      {
        title: '购买人紧急联系人添加',
        url: '#'
      },
      {
        title: '合同业务转单',
        url: '#'
      },
      {
        title: '生成定金操作',
        url: '#'
      },
      {
        title: '碑文排版技巧',
        url: '#'
      },
      {
        title: '定金转合同操作',
        url: '#'
      },
      {
        title: '安装单添加操作',
        url: '#'
      }
    ],
    moreUrl: '#'
  },
  {
    title: '系统设置文档',
    posts: []
  }
]
const todoList = [
  {
    title: '待续管理费',
    content: '20笔'
  },
  {
    title: '待签约',
    content: '5份'
  },
  {
    title: '待收费',
    content: '2笔'
  },
  {
    title: '待稽核',
    content: '5份'
  },
  {
    title: '定金管理',
    content: '2笔'
  },
  {
    title: '待修正',
    content: '20个'
  },
  {
    title: '待开发票',
    content: '5份'
  },
  {
    title: '待安葬',
    content: '2笔'
  },
  {
    title: '待处理其他工单',
    content: '5份'
  },
  {
    title: '待下库存',
    content: '2笔'
  }
]

for (let i = 0; i < 5; i++) {
  data.push({
    index: i,
    no: 'DJ202109164600',
    location: '福寿园 / 5 排 / 5号',
    name: '车晓梅',
    phone: '13512330135'
  })
  promotions.push({
    index: i,
    title: '10-30 排（起/止）1-2 墓位号（起/止）5-10 降价 500 元',
    url: '#'
  })
}

const columns = [
  {
    colKey: 'serial-number',
    title: '序号',
    width: '64',
    align: 'center'
  },
  {
    colKey: 'no',
    title: '编号'
  },
  {
    colKey: 'location',
    title: '预留位置'
  },
  {
    colKey: 'name',
    title: '客户姓名'
  },
  {
    colKey: 'phone',
    title: '联系电话'
  },
  {
    colKey: 'address',
    title: '联系地址'
  },
  {
    colKey: 'operation',
    title: '操作',
    width: 100,
    cell: 'operation',
    fixed: 'right'
  }
]
</script>

<style>
.t-table th,
.t-table td {
  padding: 12px 16px;
}

.t-table__row-full-element {
  padding: 0;
}

th {
  background: #f3f3f3 !important;
}
</style>
