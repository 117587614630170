<template>
  <t-menu class="fixed h-full top-12" @change="setstore" theme="light" :defaultValue="store.sidebarActive?store.sidebarActive:menu[0].name" width="200px" :collapsed="store.collapsed">
    <template v-for="(item, key) in menu" :key="key">
      <template v-if="item.child">
        <t-submenu :value="item.name" mode="popup">
          <template #icon>
            <div v-html="item.icon"></div>
          </template>
          <template #title>
            <span class="ml-2">{{ item.name }}</span>
          </template>
          <template v-for="(subItem, subKey) in item.child" :key="subKey">
            <t-menu-item :value="subItem.name" :to="subItem.url"> {{ subItem.name }} </t-menu-item>
          </template>
        </t-submenu>
      </template>
      <template v-else>
        <t-menu-item :value="item.name" :to="item.url">
          <template #icon>
            <div v-html="item.icon"></div>
          </template>
          <span class="ml-2">{{ item.name }}</span>
        </t-menu-item>
      </template>
    </template>
    <t-menu-item value="注销" @click="logout()">
      <template #icon>
        <div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg></div>
      </template>
      <span class="ml-2">注销</span>
    </t-menu-item>
  </t-menu>
  <div class="side-nav-placeholder"></div>
</template>

<script setup>
import { useStore } from '@/store'
const store = useStore();
const menu = store.menu;
function setstore(value) {
  if (value == "注销") return;
  store.sidebarActive = value;
  //localStorage.setItem("lastmenu", value);
}
function logout() {
  store.logout();
}
</script>

<style>
.t-default-menu {
  max-height: calc(100vh - 48px);
}

.t-default-menu .t-menu__item {
  @apply !h-10 rounded-none;
}

.t-default-menu__inner .t-menu {
  @apply p-0;
}

.t-default-menu .t-menu__item.t-is-active:not(.t-is-opened) {
  @apply bg-gray-100 text-black text-opacity-60  border-green-600;
}

.t-default-menu .t-menu__item {
  @apply border-l-4 border-solid border-transparent;
}

.t-default-menu.t-is-collapsed .t-menu .t-menu__item {
  padding: 0 14px 0 10px;
}

.side-nav-placeholder {
  flex: 1 1 200px;
  min-width: 200px;
  transition: all .3s;
}

.t-is-collapsed ~ .side-nav-placeholder{
  flex: 1 1 64px;
  min-width: 64px;
  transition: all .3s;
}
</style>
